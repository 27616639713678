import { Reducer } from "@reduxjs/toolkit";
import { PortfolioState } from "../../../components/Interfaces/PortfolioType";

const initialState: PortfolioState = {
    id: "",
    loading: true,
    name: "",
    path: "",
    description: "",
    published: false,
    regions: [],
    socialNetworks: [],
    body: [],
    lens: [],
    flash: [],
    locales: [],
    photoStyles: [],
    services: {
        pagination : { current: 0, last: 0, totalItems: 0, parPage: 0 },
        data: []
    },
    owner: {
        id: "",
        fullname: "",
        verified: false
    },
    pictures: { pagination : { current: 0, last: 0, totalItems: 0, parPage: 0 }, data: [] },
    galleries: { pagination : { current: 0, last: 0, totalItems: 0, parPage: 0 }, data: [] },
    opinions: { pagination : { current: 0, last: 0, totalItems: 0, parPage: 0 }, data: [] },
    picture: "",
    pictureForModif: null,
    banner: "",
    backgroundForModif: { id: "", minPath: ""},
    bannerOffset: 0,
    subscriberCount: 0,
    plan: "",
    canAddTestimonial: "",
    canContact: "",
    isSubscribed: false,
    avgNote: 0,
    repartition: {
        users: 0,
        photographers: 0
    },
    features: {
        pages: {
            photos: false,
            about: false,
            services: false,
            testimonials: false,
            contact: false
        },
        contact: {
            offer: false,
            appointment: false,
            buy: false,
            contact: false
        },
        actions: {
            contact: false,
            testimonial: false
        }
    },
    createdAt: ""
};

const formReducer: Reducer<PortfolioState, any> = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_NAME":
            return { ...state, name: action.payload };
        case "UPDATE_DESCRIPTION":
            return { ...state, description: action.payload };
        case "UPDATE_REGIONS":
            return { ...state, regions: action.payload };
        case "UPDATE_SOCIALNETWORK":
            return { ...state, socialNetworks: action.payload };
        case "UPDATE_BODY":
            return { ...state, body: action.payload };
        case "UPDATE_LENS":
            return { ...state, lens: action.payload };
        case "UPDATE_FLASH":
            return { ...state, flash: action.payload };
        case "UPDATE_LOCALES":
            return { ...state, locales: action.payload };
        case "UPDATE_PHOTOSTYLES":
            return { ...state, photoStyles: action.payload };
        case "UPDATE_SERVICES":
            return { ...state, services: action.payload };
        case "UPDATE_PICTURES":
            return { ...state, pictures: action.payload };
        case "UPDATE_GALLERIES":
            return { ...state, galleries: action.payload };
        case "UPDATE_OPINIONS":
            return { ...state, opinions: action.payload };
        case "UPDATE_PROFILPICTURE":
            return { ...state, pictureForModif: action.payload };
        case "UPDATE_BACKGROUNDPICTURE":
            return { ...state, backgroundForModif: action.payload };
        case "UPDATE_SUBSCRIBED": 
            return { ...state, isSubscribed: action.payload };
        case "UPDATE_REPARTITION": 
            return { ...state, repartition: action.payload };
        case "UPDATE_PORTFOLIO":
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default formReducer;
