import { Radio } from "@material-tailwind/react";

import leftZone from "../../../../assets/images/elements/watermarkinfo/bl.jpg";
import rightZone from "../../../../assets/images/elements/watermarkinfo/br.jpg";
import fullZoneZone from "../../../../assets/images/elements/watermarkinfo/full.jpg";
import noFili from "../../../../assets/images/elements/watermarkinfo/no.jpg";

import { useTranslation } from "react-i18next";
import TitleHelper from "./components/TitleHelper";
import SelectForUpload from "./components/SelectForUpload";
import { useRef } from "react";

export default function Filigrane({
    handleChangeSelectFili,
    optionChooseFili,
    handleRadio,
    sideChoose,
    handleModalToggle,
    fileLogo,
    handleChangePicture,
    withPrevi = true,
    general
}: Readonly<{
    handleChangeSelectFili: any;
    optionChooseFili: string;
    handleRadio: any;
    sideChoose: string;
    handleModalToggle: any;
    fileLogo: File | null;
    handleChangePicture: any;
    withPrevi?: boolean;
    general: any,
}>) {
    const { t } = useTranslation();
    const myInputFile = useRef<HTMLInputElement>(null);

    const handleUploadPicture = () => {
        if (myInputFile.current)
            myInputFile.current.click();
    };

    const listFili = [
        { value: "none", label: t("no") },
        { value: "ezoom", label:t("logoezoom") },
        { value: "logo", label: t("mylogo") },
    ];

    return (
        <div className=" pb-4 border-b border-gray-300">
            <div className="flex flex-row items-center w-full mt-4">
                <div className="flex flex-col w-full">
                    <TitleHelper title={t("watermark")} infobulle={t("watermark_i")} />
                    <p className="text-sm text-gray-800 mb-2">{t("watermark_d")}</p>
                    <SelectForUpload name="watermark" options={listFili} handleChangeSelect={handleChangeSelectFili} optionChoose={optionChooseFili} />
                    {optionChooseFili === "logo" && (
                        <div className="flex flex-row mt-2 bg-white gap-4 p-2 rounded-lg border w-fit mx-auto">
                            <div className="bg-gray-200 rounded-lg w-14 h-14">
                                {fileLogo ? (
                                    <img
                                        src={fileLogo ? URL.createObjectURL(fileLogo) : general?.me?.logo && general.me.logo}
                                        alt="photo_logo"
                                        className="w-full h-full object-cover object-center rounded"
                                    />
                                ) : (
                                    <img src={general?.me?.logo ?? noFili} alt="photo_logo" className="w-full h-full object-cover object-center rounded" />
                                )}
                            </div>
                            <div className="flex flex-col gap-2 pt-1">
                                <p className="text-sm font-sans">{fileLogo ? t("yourlogo") :  (general?.me?.logo ? t("yourlogo") : t("nologo"))}</p>
                                <div className="flex flex-row gap-2 items-center justify-center">
                                    <input type="file" className="hidden" ref={myInputFile} onChange={handleChangePicture} />
                                    <button className="px-2 py-1 bg-white border w-fit rounded-full text-xs" onClick={() => handleUploadPicture()}>
                                        {!fileLogo ? (general?.me?.logo ? t("change") : t("import")) : t("change")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    {(optionChooseFili === "ezoom" || optionChooseFili === "logo") && (
                        <>
                            <hr className="w-2/3 border-gray-300 mt-4 mx-auto" />
                            <div className="flex flex-row mt-4 mx-auto">
                                <Radio
                                    color="orange"
                                    name="side"
                                    id="left"
                                    value="bl"
                                    className="bg-white"
                                    checked={sideChoose === "bl"}
                                    onChange={(_arg) => handleRadio("bl")}
                                    label={<img alt="leftZone" src={leftZone} className="h-24" />}
                                    defaultChecked
                                />
                                <Radio
                                    color="orange"
                                    name="side"
                                    id="right"
                                    value="br"
                                    className="bg-white"
                                    checked={sideChoose === "br"}
                                    onChange={(_arg) => handleRadio("br")}
                                    label={<img alt="rightZone" src={rightZone} className="h-24" />}
                                />
                                {general?.me?.plan === "PRO" && (
                                    <Radio
                                        color="orange"
                                        name="side"
                                        id="full"
                                        value="full"
                                        className="bg-white"
                                        checked={sideChoose === "full"}
                                        onChange={(_arg) => handleRadio("full")}
                                        label={<img alt="fullZone" src={fullZoneZone} className="h-24" />}
                                    />
                                )}
                            </div>
                            {withPrevi && (
                                <>
                                    <hr className="w-2/3 border-gray-300 mt-4 mx-auto" />
                                    <button
                                        type="button"
                                        onClick={handleModalToggle}
                                        className="rounded-full border bg-white px-4 py-2 text-sm font-medium  hover:shadow-xl transition-all font-sans w-fit mx-auto mt-3"
                                    >
                                        {t("preview")}
                                    </button>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
