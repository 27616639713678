import moment from "moment";

export default function GaleryForPhoto({ name, img }: { name: string; img: string }) {
    return (
        <a className="w-full md:w-full mb-5" href={`/gallery/${name}`}>
            <div className="w-full md:w-full mx-auto rounded overflow-hidden shadow-lg h-full bg-white" key={name}>
                <img className="w-full object-cover h-48" src={img} alt="Sunset in the mountains" />
                <div className="px-6 py-4 flex flex-col items-center">
                    <div className="font-bold text-base mb-2 text-center">{name}</div>
                    <p className="text-gray-700 text-sm text-center">
                        {moment().format("DD.MM.YYYY")}
                    </p>
                </div>
            </div>
        </a>
    )
}