export const PlaceCustomStyle = {
    menuPortal: (base: any) => ({
        ...base,
        zIndex: 9999,
    }),
    menubar: (base: any) => ({
        ...base,
    }),
    menuList: (base: any) => ({
        ...base,
        padding: "0 0.5rem",
    }),
    control: (baseStyles: any, state: { isFocused: boolean, isDisabled: boolean }) => ({
        ...baseStyles,
        boxShadow: state.isFocused ? "0 0 0 1px rgb(239, 147, 53)" : "",
        borderColor: state.isFocused ? "rgb(239, 147, 53)" : state.isDisabled ? "rgb(236, 239, 241)" : "rgb(176, 190, 197)",
        backgroundColor: state.isDisabled ? "rgb(236, 239, 241)" : "white",
        color: state.isDisabled ? "rgb(69, 90, 100)" : "rgb(128, 128, 128)",
        "&:hover": {
            borderColor: state.isFocused ? "rgb(239, 147, 53)" : "rgb(176, 190, 197)",
        },
    }),
    option: (base: any, state: { isSelected: boolean; isFocused: boolean }) => ({
        ...base,
        background: state.isSelected ? "linear-gradient(#ef9335, #F5BA56)" : "white",
        color: state.isSelected ? "white" : "black",
        "&:hover": {
            background: state.isSelected ? "linear-gradient(#ef9335, #F5BA56)" : "lightgray",
            color: state.isSelected ? "white" : "black",
        },
        borderRadius: "0.375rem",
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
    }),
};
