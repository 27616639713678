import { GeneralReducerType } from "../components/Interfaces/GeneralReducerType";
import { Licenses } from "../enums/Licenses";
import { Regions } from "../enums/Regions";
import CatsAPI from "../services/API/Clients/CatsAPI";
import PhotoStyleAPI from "../services/API/Clients/PhotoStyleAPI";
import ServiceTypeAPI from "../services/API/Clients/ServiceTypeAPI";
import StyleAPI from "../services/API/Clients/StyleAPI";
import TypeStyleAPI from "../services/API/Clients/TypeAPI";
import UserAPI from "../services/API/Clients/UserAPI";

export const dataLoggedIn = async (): Promise<GeneralReducerType> => {
    const [respMe, respCategories, respTypeStyles, respServicesTypes, respPhotoStyles] = await Promise.all([
        UserAPI.me(),
        CatsAPI.publicCats(),
        TypeStyleAPI.types(),
        PhotoStyleAPI.styles(),
        StyleAPI.styles(),
    ]);
    return {
        categories: respCategories.body,
        typeStyles: respTypeStyles.body,
        serviceTypes: respServicesTypes.body,
        photoStyles: respPhotoStyles.body,
        me: respMe.body,
        regions: Regions,
        licences: Licenses,
        dataFetched: true,
    };
};

export const dataUnlogged = async (): Promise<GeneralReducerType> => {
    const [respCategories, respTypeStyles, respServicesTypes, respPhotoStyles] = await Promise.all([
        CatsAPI.publicCats(),
        TypeStyleAPI.types(),
        ServiceTypeAPI.services(),
        PhotoStyleAPI.styles(),
    ]);
    return {
        categories: respCategories.body,
        photoStyles: respPhotoStyles.body,
        serviceTypes: respServicesTypes.body,
        typeStyles: respTypeStyles.body,
        regions: Regions,
        licences: Licenses,
        dataFetched: true,
    };
};