import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PictureBg from "../../../assets/images/teaser/pictureBg.jpg"
import LogoWhite from "../../../assets/images/logos/SVG/whitelogo.svg";
import LogoWhiteBeta from "../../../assets/images/logos/ezoombetaneg.png"

export default function Header() {
    const { t } = useTranslation();
    return (
        <div className="relative h-5/6" style={{ height: "calc(80vh)" }}>
            <div
                className="h-screen"
                style={{
                    backgroundImage: `url(${PictureBg})`,
                    backgroundAttachment: "fixed",
                    backgroundPosition: "left",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "100vmax",
                    zIndex: -1,
                    position: "fixed",
                    top: 0,
                    left: "50%",
                    transform: "translate(-50%, 0)",
                    pointerEvents: "none",
                    backgroundPositionX: "left",
                }}
            ></div>
            <LogoWhite className="h-8 md:h-9 absolute top-4 left-4 md:top-8 md:left-8" />
            {/* <img src={LogoWhiteBeta} alt="logo" className="h-8 md:h-9 absolute top-4 left-4 md:top-8 md:left-8" /> */}
            <div className="flex flex-col top-1/3 md:-translate-y-1/2 absolute md:top-1/2 w-full md:-mt-12">
                <h1 data-entrance="from-top" className="text-white text-5xl text-center mb-6 -mt-2" style={{ textShadow: "#000000BB 1px 0 10px" }}>
                    {t("talents")}
                </h1>
            </div>
            <div className="absolute bottom-12 md:bottom-0 left-0 right-0 h-8 w-8 flex items-center justify-center rounded-full mx-auto animate-bounce">
                <FontAwesomeIcon icon="chevron-down" className="text-white text-4xl mx-auto" />
            </div>
            <div className="absolute bottom-0 right-4 md:right-8  flex items-center justify-center">
                <p className="text-white text-sm mx-auto text-right font-bold" style={{ textShadow: "#000000BB 1px 0 10px" }}>
                    Les lionnes <br />par <a href="https://loeil-sauvage-safari-prive.ch/" target="_blank">Julien Regamey</a>
                </p>
            </div>
        </div>
    );
}
