import { GeneralReducerType } from "../../components/Interfaces/GeneralReducerType"

export const getPageName = (general: GeneralReducerType, type: string, value: string) => {
    if (type === "service") return general.serviceTypes.find(item => item.enumValue === value)?.frLabel ?? value
    if (type === "style") return general.serviceTypes.find(item => item.enumValue === value)?.frLabel ?? value
    if (type === "region") return general.regions.find(item => item.code === value)?.frLabel ?? value
    return null;
}

const ALLOWED_TYPES = ["service", "style", "region"];

export const getPageOverride = (type?: string, value?: string) => {
    if (!type || !value) return { };
    if (!ALLOWED_TYPES.includes(type)) return { };
    if (type === "region") return { region: value, country: "CHE"};
    return { [type]: value };
}