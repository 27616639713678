import APIClient from "../APIClients";

export default class KeywordsAPI {
    public static async keywords() {
        return APIClient.ezoom.get(`/keywords`);
    }

    public static async keywordsAutoComplete(autoComplete: string) {
        return APIClient.ezoom.get(`/keywords?autocomplete=${autoComplete}`);
    }

    public static async keywordsCategory(category: string) {
        return APIClient.ezoom.get(`/keywords?category=${category}`);
    }

    public static async keywordsAutoCompleteCategory(autoComplete: string, category: string) {
        return APIClient.ezoom.get(`/keywords?fr_label=${autoComplete}&category=${category}`);
    }

    public static async newKeywords(data: {}) {
        return APIClient.ezoom.post(`/keywords`, data);
    }

    public static async getKeyword(id: string) {
        return APIClient.ezoom.get(`/keywords/${id}`);
    }

    public static async putKeyword(data: {}, id: string) {
        return APIClient.ezoom.put(`/keywords/${id}`, data);
    }

    public static async deleteKeyword(id: string) {
        return APIClient.ezoom.delete(`/keywords/${id}`);
    }
}
