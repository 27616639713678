import { faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsNc, faCreativeCommonsNd, faCreativeCommonsSa, faCreativeCommonsZero } from "@fortawesome/free-brands-svg-icons";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import ModalChangePlan from "../../../../components/Modals/ModalChangePlan";
import LinkGeneric from "./components/LinkGeneric";
import SelectForUpload from "./components/SelectForUpload";
import TitleDescribe from "./components/TitleDescribe";

const listLicence = [
    { value: 'all-rights-reserved', label: "Tous droits réservés", icons: [faCopyright]},
    { value: 'own-license', label: "Licence propriétaire", icons: [faCopyright]},
    { value: 'cc0', label: "CC0 1.0", icons: [faCreativeCommons, faCreativeCommonsZero]},
    { value: 'cc-by', label: "CC BY 4.0", icons: [faCreativeCommons, faCreativeCommonsBy] },
    { value: 'cc-by-sa', label: "CC BY-SA 4.0", icons: [faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsSa] },
    { value: 'cc-by-nd', label: "CC BY-ND 4.0", icons: [faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsNd] },
    { value: 'cc-by-nc', label: "CC BY-NC 4.0", icons: [faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsNc] },
    { value: 'cc-by-nc-sa', label: "CC BY-NC-SA 4.0", icons: [faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsNc, faCreativeCommonsSa] },
    { value: 'cc-by-nc-nd', label: "CC BY-NC-ND 4.0", icons: [faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsNc, faCreativeCommonsNd] },
]

export default function Licence({ optionChoose, handleChangeLicence, general }: Readonly<{ optionChoose: string, handleChangeLicence: any, general: any}>) {
    const [openModal, setOpenModal] = React.useState<boolean>(false);

    return (
        <div className=" pb-4 border-b border-gray-300">
            <ModalChangePlan open={openModal} setOpen={setOpenModal} />
            <div className="flex flex-row justify-between items-center w-full mt-4">
                <div className="flex flex-col w-full pr-2">
                    <TitleDescribe title="userights_license" describe="license_d" />
                    <div className="w-full h-full relative">
                        <SelectForUpload name="userights_license" options={listLicence} optionChoose={optionChoose} handleChangeSelect={handleChangeLicence} />
                    </div>
                    <LinkGeneric link="https://fr.wikipedia.org/wiki/Licence_Creative_Commons" title="learnmorecc" />
                </div>
            </div>
        </div>
    )
}