import { Dialog } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalWrapper from "../../Modals/ModalWrapper";

import { faCopyright } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { Storage } from "../../../services/storage";
import { PictureSearch, PictureType } from "../../Interfaces/PictureType";

import FavoriteAPI from "../../../services/API/Clients/FavoriteAPI";
import PicturesAPI from "../../../services/API/Clients/PicturesAPI";

import { A11y, Navigation, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { handleBuyIt } from "./components/PictureModalBuyIt";
import { handleDelete } from "./components/PictureModalDelete";
import { removeNsfw } from "./components/PictureModalNsfw";
import { openReportModal } from "./components/PictureModalReport";
import PictureModalWithoutFullScreen from "./components/PictureModalWithoutFullScreen";
import { usePictureEdit } from "../../../providers/PictureEditProvider";

export default function PictureModal({
    open,
    setOpen,
    ids,
    pictures,
    callNextPictures,
}: Readonly<{ open: boolean; setOpen: (arg: boolean) => void; ids: string; pictures: PictureSearch[]; callNextPictures?: Function }>) {
    const { t } = useTranslation();
    const [actualId, setActualId] = React.useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const [dataActualItem, setDataActualItem] = React.useState<PictureType>({
        id: "",
        minPath: "",
        publicPath: "",
        exifData: [],
        portfolio: {
            id: "",
            name: "",
            picture: "",
            publicLink: "",
        },
        keywords: [],
        category: {
            enumValue: "",
            frLabel: "",
            enLabel: "",
        },
        watermark: "",
        nsfw: false,
        identifiablePeople: false,
        identifiableProperties: false,
        writtenContract: false,
        license: "",
        galleries: [],
        portfolios: [],
        uploadedAt: "",
        favoriteCount: 0,
        viewCount: 0,
        isFavorited: false,
        watermarkUptoDate: false,
        canBeBought: false,
    });
    const [repartition, setRepartition] = useState<{
        users: number;
        photographers: number;
    }>({
        users: 0,
        photographers: 0,
    });
    const [hover, setHover] = React.useState(false);
    const [isFullScreen, setIsFullScreen] = React.useState(false);
    const [currentPicture, setCurrentPicture] = React.useState<number>(0);
    const [printNsfw, setPrintNsfw] = React.useState(false);
    const myId = Storage.getId();

    const { editData } = usePictureEdit();

    useEffect(() => {
        const foundItem = pictures.find((item) => item.id === ids);
        if (!foundItem) return;
        const actualIndexOfItem = pictures.indexOf(foundItem);
        setActualId(ids);
        setCurrentPicture(actualIndexOfItem);
        fetchData(true, ids);
        if (Storage.getCookie() === "true") setPrintNsfw(true);
        else setPrintNsfw(false);
    }, [ids]);

    const handleChangeFav = async () => {
        if (myId) {
            const data = {
                picture: `/api/public/pictures/${dataActualItem.id}`,
                owner: `/api/users/${myId}`,
            };
            if (!dataActualItem.isFavorited) {
                const response = await FavoriteAPI.fav(data);
                if (response.status === 200 || response.status === 201) toast.success(t("addToFav"));
                else toast.error(t("addToFavError"));
            } else {
                const response = await FavoriteAPI.unFav(data);
                if (response.status === 200 || response.status === 201 || response.status === 204) toast.success(t("removeFromFav"));
                else toast.error(t("removeFromFavError"));
            }
            fetchData(false);
        }
    };

    const fetchData = async (isLoading: boolean, ids?: string) => {
        if (isLoading)
            setLoading(true);
        if (ids === "" || !ids) {
            if (actualId === "") {
                return;
            } else {
                await PicturesAPI.addView({ picture: `/api/public/pictures/${actualId}` });
                const response = await PicturesAPI.getPicture(actualId);
                if (response.status === 200 || response.status === 201) {
                    setDataActualItem(response.body);
                    const responseRepartition = await PicturesAPI.getRepartition(actualId);
                    if (response.status === 200 || response.status === 201) {
                        setRepartition(responseRepartition.body);
                        setLoading(false);
                    }
                }
            }
        } else {
            await PicturesAPI.addView({ picture: `/api/public/pictures/${ids}` });
            const response = await PicturesAPI.getPicture(ids);
            if (response.status === 200 || response.status === 201) {
                setDataActualItem(response.body);
                const responseRepartition = await PicturesAPI.getRepartition(ids);
                if (response.status === 200 || response.status === 201) {
                    setRepartition(responseRepartition.body);
                    setLoading(false);
                }
            }
        }
    };

    const myCallBackFunction = (swiper: any) => {
        setLoading(true);
        const targetIndex = swiper.realIndex;
        const targetItem = pictures[targetIndex];
        setActualId(targetItem.id);
        if (targetIndex >= pictures.length - 5 && targetIndex < pictures.length + 1 && callNextPictures) callNextPictures();
        fetchData(true, targetItem.id);
    };

    const enterFullScreen = () => {
        if (document.documentElement.requestFullscreen) document.documentElement.requestFullscreen();
        setIsFullScreen(true);
    };

    const exitFullScreen = () => {
        if (document.exitFullscreen) document.exitFullscreen();
        setIsFullScreen(false);
    };

    const onSaveEdit = () => {
        fetchData(true);
    };

    useEffect(() => {
        const handleKeyUp = (event: KeyboardEvent) => {
            if (event.key === "Escape" && isFullScreen) {
                exitFullScreen();
            }
        };
        document.addEventListener("keyup", handleKeyUp);
        const handleFullscreenChange = () => {
            setIsFullScreen(!!document.fullscreenElement);
        };
        document.addEventListener("fullscreenchange", handleFullscreenChange);
        document.addEventListener("mozfullscreenchange", handleFullscreenChange);
        document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
        document.addEventListener("msfullscreenchange", handleFullscreenChange);
        return () => {
            document.removeEventListener("keyup", handleKeyUp);
            document.removeEventListener("fullscreenchange", handleFullscreenChange);
            document.removeEventListener("mozfullscreenchange", handleFullscreenChange);
            document.removeEventListener("webkitfullscreenchange", handleFullscreenChange);
            document.removeEventListener("msfullscreenchange", handleFullscreenChange);
        };
    }, [isFullScreen]);

    return (
        <ModalWrapper open={open} setOpen={setOpen} padding={false} persistant={editData !== undefined}>
            <Dialog.Panel className="transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all min-h-screen max-h-screen h-screen overflow-y-auto max-w-screen max-w-screen w-screen">
                <button
                    className="relative w-screen bg-black flex flex-col justify-center items-center"
                    style={{ height: isFullScreen ? "100vh" : "90vh" }}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    <div
                        className={`absolute z-50 top-2 w-screen opacity-100 ${hover ? "md:transition-all md:opacity-100" : "md:transition-all md:opacity-0 "
                            } flex duration-300 transition-all flex-row justify-between py-2 px-8`}
                    >
                        <FontAwesomeIcon
                            icon="x"
                            className="text-white/50 z-50 text-2xl cursor-pointer"
                            onClick={() => {
                                if (isFullScreen) exitFullScreen();
                                setOpen(false);
                            }}
                        />
                        <FontAwesomeIcon
                            icon="expand"
                            className="text-white/50 text-2xl cursor-pointer"
                            onClick={() => (isFullScreen ? exitFullScreen() : enterFullScreen())}
                        />
                    </div>
                    <div className="w-screen flex gap-0 items-center justify-center" style={{ height: isFullScreen ? "80vh" : "70vh" }}>
                        <Swiper
                            modules={[Navigation, Scrollbar, A11y]}
                            spaceBetween={50}
                            slidesPerView={1}
                            navigation
                            initialSlide={currentPicture}
                            onSwiper={(swiper: any) => myCallBackFunction(swiper)}
                            onSlideChange={(swiper: any) => myCallBackFunction(swiper)}
                        >
                            {pictures.map((picture: any) => (
                                <SwiperSlide key={picture.id} onClick={() => picture.nsfw && !printNsfw && removeNsfw(setPrintNsfw, t)}>
                                    <div
                                        key={picture.id}
                                        className={`w-screen flex items-center justify-center max-h-[70vh] ${picture.nsfw && !printNsfw && "blur-lg"}`}
                                    >
                                        <div className="w-screen h-[70vh] flex items-center justify-center p-2">
                                            <div className="h-[65vh] max-w-[65vw] md:max-w-[80vw] relative">
                                                <img
                                                    alt={picture.image}
                                                    src={picture.id !== dataActualItem.id ? picture.minPath : dataActualItem.publicPath}
                                                    className="object-contain w-full h-full"
                                                />
                                                {!loading && !dataActualItem.watermarkUptoDate && (
                                                    <div className="absolute bottom-0 w-full px-4 py-2 bg-orange-500/50 text-white text-xs sm:text-sm md:text-base font-bold">
                                                        {t("creating_watermark")}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    <div className="absolute bottom-2 right-0 flex flex-col justify-end items-end w-screen px-8">
                        {dataActualItem?.title && <p className="text-xs sm:text-sm md:text-base font-bold text-white text-right">{dataActualItem.title}</p>}
                        <div className="flex flex-row justify-end items-center w-full">
                            <FontAwesomeIcon icon={faCopyright} className="text-xs sm:text-sm md:text-base text-white mr-2" />
                            <p className="text-xs sm:text-sm md:text-base font-bold text-white text-right">{t("allrightreserved")}</p>
                        </div>
                    </div>
                </button>
                {!isFullScreen && (
                    <PictureModalWithoutFullScreen
                        dataActualItem={dataActualItem}
                        repartition={repartition}
                        handleChangeFav={handleChangeFav}
                        actualId={actualId}
                        handleBuyIt={handleBuyIt}
                        handleDelete={handleDelete}
                        openReportModal={openReportModal}
                        loading={loading}
                        onSaveEdit={onSaveEdit}
                    />
                )}
            </Dialog.Panel>
        </ModalWrapper >
    );
}
