import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function ColorPhoto(pathname: string) {
    if (pathname === "photos")
        return "text-white bg-orange-500 border-orange-500"
    else
        return "bg-white text-gray-500 border-gray-400 hover:text-orange-500 hover:border-orange-500 hover:z-10";
}

function ColorGrapher(pathname: string) {
    if (pathname === "photographs")
        return "text-white bg-purple-500 border-purple-500"
    else
        return "bg-white text-gray-500 border-gray-400 hover:text-purple-500 hover:border-purple-500";
}

export default function Research({ onglet, setOnglet, kindOfResearch, setKindOfResearch } : Readonly<{ onglet: string, setOnglet: Function, kindOfResearch: string, setKindOfResearch: Function }>) {
    const { t } = useTranslation();
    return (
        <>
            <div className="flex my-2">
                <div className="group flex relative w-1/2">
                    <span
                        className={`${ColorPhoto(
                            onglet
                        )} transition-all py-1.5 px-3 cursor-pointer border rounded-l-full text-sm flex items-center justify-center w-full`}
                        onClick={() => setOnglet("photos")}
                    >
                        {t("photos")}
                    </span>
                </div>
                <div className="group flex relative w-1/2">
                    <span
                        className={`${ColorGrapher(
                            onglet
                        )} transition-all py-1.5 px-3 cursor-pointer border rounded-r-full text-sm flex items-center justify-center w-full`}
                        onClick={() => setOnglet("photographs")}
                        style={{ marginLeft: "-1px" }}
                    >
                        {t("photographs")}
                    </span>
                </div>
            </div>
            {onglet === "photos" &&
                <div>
                    <Link to="/popular">
                        <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                            <FontAwesomeIcon icon="fire" className="mr-2 w-4" />
                            {t("populars")}
                        </button>
                    </Link>
                    <Link to="/recent">
                        <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                            <FontAwesomeIcon icon="clock" className="mr-2 w-4" />
                            {t("recents")}
                        </button>
                    </Link>
                    <Link to="/galeries">
                        <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                            <FontAwesomeIcon icon="images" className="mr-2 w-4" />
                            {t("galeries")}
                        </button>
                    </Link>
                    <button
                        onClick={() => setKindOfResearch("catsPhoto")}
                        className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg flex justify-between items-center"
                    >
                        <div className="flex flex-row items-center">
                            <FontAwesomeIcon icon="folder" className="mr-2 w-4" />
                            {t("categories")}
                        </div>
                        <FontAwesomeIcon icon="chevron-right" />
                    </button>
                    <button
                        onClick={() => setKindOfResearch("typesPhoto")}
                        className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg flex justify-between items-center"
                    >
                        <div className="flex flex-row items-center">
                            <FontAwesomeIcon icon="th" className="mr-2 w-4" />
                            {t("type")}
                        </div>
                        <FontAwesomeIcon icon="chevron-right" />
                    </button>
                    <button
                        onClick={() => setKindOfResearch("rightsPhoto")}
                        className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg flex justify-between items-center"
                    >
                        <div className="flex flex-row items-center">
                            <FontAwesomeIcon icon="copyright" className="mr-2 w-4" />
                            {t("userights")}
                        </div>
                        <FontAwesomeIcon icon="chevron-right" />
                    </button>
                    <button
                        onClick={() => setKindOfResearch("regionsPhoto")}
                        className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg flex justify-between items-center"
                    >
                        <div className="flex flex-row items-center">
                            <FontAwesomeIcon icon="globe" className="mr-2 w-4" />
                            {t("regions")}
                        </div>
                        <FontAwesomeIcon icon="chevron-right" />
                    </button>
                    <button
                        onClick={() => setKindOfResearch("datePhoto")}
                        className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg flex justify-between items-center"
                    >
                        <div className="flex flex-row items-center">
                            <FontAwesomeIcon icon="calendar" className="mr-2 w-4" />
                            {t("date")}
                        </div>
                        <FontAwesomeIcon icon="chevron-right" />
                    </button>
                </div>
            }
            {onglet === "photographs" &&
                <div>
                    <Link to="/popular-photographers">
                        <button className="my-2 hover:bg-gradient-to-r hover:from-purple-500 hover:to-purple-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                            <FontAwesomeIcon icon="fire" className="mr-2 w-4" />
                            {t("populars")}
                        </button>
                    </Link>
                    <Link to="/recent-photographers">
                        <button className="my-2 hover:bg-gradient-to-r hover:from-purple-500 hover:to-purple-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                            <FontAwesomeIcon icon="clock" className="mr-2 w-4" />
                            {t("recentsphotographers")}
                        </button>
                    </Link>
                    <button
                        onClick={() => setKindOfResearch("servicesPhotograph")}
                        className="my-2 hover:bg-gradient-to-r hover:from-purple-500 hover:to-purple-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg flex justify-between items-center"
                    >
                        <div className="flex flex-row items-center">
                            <FontAwesomeIcon icon="camera" className="mr-2 w-4" />
                            {t("performances")}
                        </div>
                        <FontAwesomeIcon icon="chevron-right" />
                    </button>
                    <button
                        onClick={() => setKindOfResearch("stylesPhotograph")}
                        className="my-2 hover:bg-gradient-to-r hover:from-purple-500 hover:to-purple-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg flex justify-between items-center"
                    >
                        <div className="flex flex-row items-center">
                            <FontAwesomeIcon icon="mountain-sun" className="mr-2 w-4" />
                            {t("style")}
                        </div>
                        <FontAwesomeIcon icon="chevron-right" />
                    </button>
                    <button
                        onClick={() => setKindOfResearch("regionsPhotograph")}
                        className="my-2 hover:bg-gradient-to-r hover:from-purple-500 hover:to-purple-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg flex justify-between items-center"
                    >
                        <div className="flex flex-row items-center">
                            <FontAwesomeIcon icon="globe" className="mr-2 w-4" />
                            {t("regions")}
                        </div>
                        <FontAwesomeIcon icon="chevron-right" />
                    </button>
                </div>
            }
        </>
    )
}