import { Dialog } from "@headlessui/react";
import ModalWrapper from "./ModalWrapper";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardBody, CardHeader, Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";

export default function ModalChangePlan({ open, setOpen }: Readonly<{ open: boolean; setOpen: any }>) {
    const { t } = useTranslation();
    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="transform overflow-hidden rounded-2xl bg-white p-6 py-12 text-left align-middle shadow-xl transition-all max-w-3xl">
                <Dialog.Title as="h3" className="text-2xl font-medium leading-6 text-orange-500 text-center">
                    {t("boostyouraccount")}
                    <FontAwesomeIcon icon="crown" className="ml-2" />
                </Dialog.Title>
                <div className="flex justify-between items-start gap-4 mt-8">
                    <Card color="orange" variant="gradient" className="w-full xl:w-2/3 px-8 py-4 mx-auto shadow-lg h-fit">
                        <CardHeader
                            floated={false}
                            shadow={false}
                            color="transparent"
                            className="m-0 mb-8 rounded-none border-b border-white/10 pb-4 text-center"
                        >
                            <Typography variant="small" color="white" className="font-normal uppercase">
                                {t("premium")}
                            </Typography>
                            <Typography color="white" className="mt-2 justify-center gap-1 text-2xl xl:text-3xl 2xl:text-4xl font-normal">
                                <span className="mt-2 mr-2 text-base 2xl:text-2xl">{t("chf")}</span>
                                <span>15.90</span>
                                <span className="self-end text-base 2xl:text-2xl">{t("permonth")}</span>
                            </Typography>
                        </CardHeader>
                        <CardBody className="p-0 pb-4 text-sm border-b border-white/10">
                            <p dangerouslySetInnerHTML={{ __html: t("premium_summary") }}></p>
                        </CardBody>
                        <Link to="/subscription/premium" className="text-sm text-center text-white hover:text-gray-200 hover:tracking-wider mt-2">En savoir plus</Link>
                        <Link to="/subscription/premium" className="mt-2 w-full bg-white text-orange-500 hover:bg-orange-50 hover:text-orange-600 py-3 rounded-lg font-medium text-center">
                            {t("subscribe")}
                        </Link>
                    </Card>

                    <Card color="purple" variant="gradient" className="w-full xl:w-2/3 px-8 py-4 mx-auto shadow-lg h-fit">
                        <CardHeader
                            floated={false}
                            shadow={false}
                            color="transparent"
                            className="m-0 mb-8 rounded-none border-b border-white/10 pb-4 text-center"
                        >
                            <Typography variant="small" color="white" className="font-normal uppercase">
                                {t("pro")}
                            </Typography>
                            <Typography color="white" className="mt-2 justify-center gap-1 text-2xl xl:text-3xl 2xl:text-4xl font-normal">
                                <span className="mt-2 mr-2 text-base 2xl:text-2xl">{t("chf")}</span>
                                <span>15.90</span>
                                <span className="self-end text-base 2xl:text-2xl">{t("permonth")}</span>
                            </Typography>
                        </CardHeader>
                        <CardBody className="p-0 pb-4 text-sm border-b border-white/10">
                            <p dangerouslySetInnerHTML={{ __html: t("pro_summary") }}></p>
                        </CardBody>
                        <Link to="/subscription/pro" className="text-sm text-center text-white hover:text-gray-200 mt-2">En savoir plus</Link>
                        <Link to="/subscription/pro" className="mt-2 w-full bg-white text-purple-500 hover:bg-purple-50 hover:text-purple-600 py-3 rounded-lg font-medium text-center">
                            {t("subscribe")}
                        </Link>
                    </Card>
                </div>
            </Dialog.Panel>
        </ModalWrapper>
    );
}
