import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, PopoverContent, PopoverHandler, Typography, list } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { Storage } from "../../../services/storage";
import { useEffect, useState } from "react";
import { Language } from "../../../enums/Language";
import Research from "./Research";
import Calendar from "react-calendar";
import moment from "moment";
import ShowMap from "./Map";
import { faCreativeCommonsBy, faCreativeCommonsNc, faCreativeCommonsNd, faCreativeCommonsSa, faCreativeCommonsZero } from "@fortawesome/free-brands-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import StyleAPI from "../../../services/API/Clients/StyleAPI";
import ServiceTypeAPI from "../../../services/API/Clients/ServiceTypeAPI";
import PicturesAPI from "../../../services/API/Clients/PicturesAPI";
import TypeStyleAPI from "../../../services/API/Clients/TypeAPI";
import CatsAPI from "../../../services/API/Clients/CatsAPI";
import RegionAPI from "../../../services/API/Clients/RegionAPI";

/*** */

import React from "react";
import {
  Drawer,
  Button,
  IconButton,
} from "@material-tailwind/react";
import { HashLink } from "react-router-hash-link";

/** */

export default function MenuHeader() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const signin = Storage.getToken() === null;
    const { i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const [onglet, setOnglet] = useState("");
    const [kindOfResearch, setKindOfResearch] = useState("");
    const [list, setList] = useState<any>([]);

    const changeLanguage = (language: string) => {
        switch (language) {
            case Language.EN:
                i18n.changeLanguage(Language.EN);
                localStorage.setItem("lang", "en");
                break;
            case Language.IT:
                i18n.changeLanguage(Language.IT);
                localStorage.setItem("lang", "it");
                break;
            case Language.DE:
                i18n.changeLanguage(Language.DE);
                localStorage.setItem("lang", "de");
                break;
            case Language.FR:
            default:
                i18n.changeLanguage(Language.FR);
                localStorage.setItem("lang", "fr");
                break;
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            switch (kindOfResearch) {
                case "catsPhoto":
                    const responseCats = await CatsAPI.publicCatsMostUsed();
                    if (responseCats.status === 200 || responseCats.status === 201) setList(responseCats.body);
                    break;
                case "typesPhoto":
                    const responseTypes = await TypeStyleAPI.typesMostUsed();
                    if (responseTypes.status === 200 || responseTypes.status === 201) setList(responseTypes.body);
                    break;
                case "rightsPhoto":
                    const responseLicenses = await PicturesAPI.licensesMostUsed();
                    if (responseLicenses.status === 200 || responseLicenses.status === 201) setList(responseLicenses.body);
                    break;
                case "servicesPhotograph":
                    const responsePerf = await ServiceTypeAPI.servicesMostUsed();
                    if (responsePerf.status === 200 || responsePerf.status === 201) setList(responsePerf.body);
                    break;
                case "stylesPhotograph":
                    const responseStyle = await StyleAPI.stylesMostUsed();
                    if (responseStyle.status === 200 || responseStyle.status === 201) setList(responseStyle.body);
                    break;
                case "regionsPhoto":
                    const responseRegions = await RegionAPI.mostUsed();
                    if (responseRegions.status === 200 || responseRegions.status === 201) setList(responseRegions.body);
                    break;
                case "regionsPhotograph":
                    const responseRegionsPhotograph = await RegionAPI.mostUsedPortfolio();
                    if (responseRegionsPhotograph.status === 200 || responseRegionsPhotograph.status === 201) setList(responseRegionsPhotograph.body);
                    break;
                case "datePhoto":
                    const response = await PicturesAPI.datesMostUsed();
                    if (response.status === 200 || response.status === 201) setList(response.body);
                    break;
                default:
                    break;
            }
        };
        if (!open) return;
        fetchData();
    }, [open, kindOfResearch]);

    const currentUrlIsHomeUnlogged = window.location.pathname === "/" && signin;

    const ReturnMyData = () => {
        if (
            kindOfResearch === "catsPhoto" ||
            kindOfResearch === "typesPhoto" ||
            kindOfResearch === "servicesPhotograph" ||
            kindOfResearch === "stylesPhotograph"
        ) {
            return list.map((item: { enumValue: string; frLabel: string; enLabel: string }) => {
                let urlReturn: string = "";
                if (kindOfResearch === "catsPhoto") urlReturn = "/search/category/";
                if (kindOfResearch === "typesPhoto") urlReturn = "/search/typestyle/";
                if (kindOfResearch === "servicesPhotograph") urlReturn = "/photographers/category/";
                if (kindOfResearch === "stylesPhotograph") urlReturn = "/photographers/style/";

                return (
                    <Link to={urlReturn + item.enumValue}>
                        <button
                            className={`my-2 hover:bg-gradient-to-r ${
                                onglet === "photos" ? "hover:from-orange-500 hover:to-orange-100" : "hover:from-purple-500 hover:to-purple-100"
                            } hover:text-white text-gray-700 p-2 text-sm w-full text-left rounded-lg`}
                        >
                            {item.frLabel}
                        </button>
                    </Link>
                );
            });
        } else if (kindOfResearch === "regionsPhoto" || kindOfResearch === "regionsPhotograph" || kindOfResearch === "rightsPhoto") {
            return list.map((item: string) => {
                let urlReturn: string = "";
                if (kindOfResearch === "regionsPhoto") urlReturn = "/search/region/";
                if (kindOfResearch === "regionsPhotograph") urlReturn = "/photographers/region/";
                if (kindOfResearch === "rightsPhoto") urlReturn = "/search/license/";

                return (
                    <Link to={urlReturn + item} key={item}>
                        <button
                            className={`my-2 hover:bg-gradient-to-r ${
                                onglet === "photos" ? "hover:from-orange-500 hover:to-orange-100" : "hover:from-purple-500 hover:to-purple-100"
                            } hover:text-white text-gray-700 p-2 text-sm w-full text-left rounded-lg`}
                        >
                            {t(item)}
                        </button>
                    </Link>
                );
            });
        } else {
            return list.map((item: string) => {
                let urlReturn: string = "";
                if (kindOfResearch === "datePhoto") urlReturn = "/search/date/";

                return (
                    <Link to={urlReturn + item} key={item}>
                        <button
                            className={`my-2 hover:bg-gradient-to-r ${
                                onglet === "photos" ? "hover:from-orange-500 hover:to-orange-100" : "hover:from-purple-500 hover:to-purple-100"
                            } hover:text-white text-gray-700 p-2 text-sm w-full text-left rounded-lg`}
                        >
                            {item}
                        </button>
                    </Link>
                );
            });
        }
    };

    if (window.innerWidth < 768)
        return (
        <Popover
            placement="bottom"
            animate={{
                mount: { opacity: "1", scale: "1" },
                unmount: { opacity: "0", scale: "0.8" },
            }}
        >
            <PopoverHandler onClick={() => setOpen(!open)}>
                <div className="px-2 mt-2 mr-1 w-6 text-center cursor-pointer">
                    <FontAwesomeIcon icon={open ? `x` : `bars`} className={`${currentUrlIsHomeUnlogged ? "text-white" : "text-black"}`} />
                </div>
            </PopoverHandler>
            <PopoverContent
                className={`z-40 absolute rounded-none top-0 left-0 flex-col px-4 py-2 w-full h-[calc(100vh-50px)] overflow-y-auto bg-white focus:outline-none font-thin`}
            >
                {kindOfResearch === "" && (
                    <div className="py-1">
                        {signin && (
                            <>
                                <h2 className="text-sm font-bold text-left text-gray-700">{t("noYetSubscribe")}</h2>
                                <div className="flex flex-row items-center justify-between text-center gap-2 my-2">
                                    <Link
                                        className={`w-1/2 border-2 border-transparent inline bg-gray-200/30 hover:bg-white py-1 px-4 rounded-full text-black hover:shadow-lg transition-all text-center whitespace-nowrap cursor-pointer backdrop-blur-sm flex justify-center`}
                                        to="/signin"
                                    >
                                        <span className="flex">{t("signin")}</span>
                                    </Link>
                                    <Link
                                        to="/signup"
                                        className="w-1/2 border-2 border-transparent inline bg-orange-500 hover:bg-orange-200 py-1 px-4 rounded-full text-white hover:text-black transition-all text-center whitespace-nowrap cursor-pointer flex justify-center"
                                    >
                                        <span className="flex">{t("signup")}</span>
                                    </Link>
                                </div>
                                <hr className="mt-2 mb-4" />
                            </>
                        )}
                        <h2 className="text-sm font-bold text-left text-gray-700">{t("whatSearch")}</h2>
                        <Research
                            kindOfResearch={kindOfResearch}
                            setKindOfResearch={setKindOfResearch}
                            onglet={onglet}
                            setOnglet={setOnglet}
                        />
                        <hr className="mt-5 mb-4" />
                        <h2 className="text-sm font-bold text-left text-gray-700">{t("yourPhotograph")}</h2>
                        <Link to="/portfolio/1/photos">
                            <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-700 p-2 text-sm w-full text-left rounded-lg">
                                <FontAwesomeIcon icon="check" className="mr-2 w-4" />
                                {t("yourAdvantages")}
                            </button>
                        </Link>
                        <Link to="/upload">
                            <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-700 p-2 text-sm w-full text-left rounded-lg">
                                <FontAwesomeIcon icon="money-bill" className="mr-2 w-4" />
                                {t("abosTarifs")}
                            </button>
                        </Link>
                        <Link to="/favorites">
                            <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-700 p-2 text-sm w-full text-left rounded-lg">
                                <FontAwesomeIcon icon="question-circle" className="mr-2 w-4" />
                                {t("help")}
                            </button>
                        </Link>

                        <hr className="mt-2 mb-4" />
                        <h2 className="text-sm font-bold text-left text-gray-700">{t("enterprise")}</h2>
                        <Link to="/portfolio/1/photos">
                            <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-700 p-2 text-sm w-full text-left rounded-lg">
                                <FontAwesomeIcon icon="info-circle" className="mr-2 w-4" />
                                {t("about")}
                            </button>
                        </Link>
                        <Link to="/portfolio/1/photos">
                            <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-700 p-2 text-sm w-full text-left rounded-lg">
                                <FontAwesomeIcon icon="gavel" className="mr-2 w-4" />
                                {t("ourpolitic")}
                            </button>
                        </Link>
                        <Link to="/portfolio/1/photos">
                            <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-700 p-2 text-sm w-full text-left rounded-lg">
                                <FontAwesomeIcon icon="envelope" className="mr-2 w-4" />
                                {t("contact")}
                            </button>
                        </Link>
                        <Link to="/portfolio/1/photos">
                            <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-700 p-2 text-sm w-full text-left rounded-lg">
                                <FontAwesomeIcon icon="briefcase" className="mr-2 w-4" />
                                {t("jobs")}
                            </button>
                        </Link>

                        <hr className="my-2" />
                        <Link to="/portfolio/1/photos">
                            <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-700 p-2 text-sm w-full text-left rounded-lg">
                                <FontAwesomeIcon icon="question" className="mr-2 w-4" />
                                {t("help")}
                            </button>
                        </Link>
                        <Link to="/portfolio/1/photos">
                            <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-700 p-2 text-sm w-full text-left rounded-lg">
                                <FontAwesomeIcon icon="comment" className="mr-2 w-4" />
                                {t("avis")}
                            </button>
                        </Link>
                        <Link to="/privacy">
                            <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-700 p-2 text-sm w-full text-left rounded-lg">
                                <FontAwesomeIcon icon="shield" className="mr-2 w-4" />
                                {t("privacy")}
                            </button>
                        </Link>
                        <Link to="/CGU">
                            <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-700 p-2 text-sm w-full text-left rounded-lg">
                                <FontAwesomeIcon icon="file-alt" className="mr-2 w-4" />
                                {t("CGU")}
                            </button>
                        </Link>

                        <hr className="my-2" />
                        <div className="flex flex-row flex-wrap items-center justify-around text-center gap-2 my-2">
                            <button onClick={() => changeLanguage(Language.FR)} className="flex flex-row items-center text-center gap-2">
                                🇫🇷 {t("french_d")}
                            </button>
                            <button onClick={() => changeLanguage(Language.EN)} className="flex flex-row items-center text-center gap-2">
                                🇬🇧 {t("english_d")}
                            </button>
                            <button onClick={() => changeLanguage(Language.IT)} className="flex flex-row items-center text-center gap-2">
                                🇮🇹 {t("italiano_d")}
                            </button>
                            <button onClick={() => changeLanguage(Language.DE)} className="flex flex-row items-center text-center gap-2">
                                🇩🇪 {t("deutsch_d")}
                            </button>
                        </div>
                    </div>
                )}
                {kindOfResearch && (
                    <div className="py-1">
                        <div className="flex flex-row justify-between items-center">
                            <button
                                onClick={() => setKindOfResearch("")}
                                className="my-2 text-gray-700 p-2 text-sm w-fit text-left rounded-lg flex justify-between items-center"
                            >
                                <div className="flex flex-row items-center">
                                    <FontAwesomeIcon icon="arrow-left" className="mr-2 w-4" />
                                </div>
                            </button>
                            <h2 className="text-base font-bold text-right text-gray-700 w-full mr-2">{t(kindOfResearch)}</h2>
                        </div>

                        {list.length > 0
                            ? <ReturnMyData />
                        : (
                            <div className="flex flex-col justify-center items-center w-full rounded-lg bg-gray-100 my-2">
                                <Typography className="text-base text-gray-700 my-4">{t("noData")}</Typography>
                            </div>
                        )}
                    </div>
                )}
            </PopoverContent>
        </Popover>
    );

    return (
        <React.Fragment>
            <button onClick={() => setOpen(!open)} className="px-2 md:-mt-1.5  mr-1 w-6 text-center cursor-pointer">
                <FontAwesomeIcon icon={open ? `x` : `bars`} className={`${currentUrlIsHomeUnlogged ? "text-white" : "text-black"}`} />
            </button>
            <Drawer open={open} className="p-4 overflow-y-auto mt-12 pb-12 md:pb-14" placement="right" overlay={false}>
                {kindOfResearch === "" && (
                     <div className="py-1">
                         {signin && (
                             <>
                                 <h2 className="text-sm font-bold text-left text-gray-700">{t("noYetSubscribe")}</h2>
                                 <div className="flex flex-row items-center justify-between text-center gap-2 my-2">
                                     <Link
                                         className={`w-1/2 border-2 border-transparent inline bg-gray-200/30 hover:bg-white py-1 px-4 rounded-full text-black hover:shadow-lg transition-all text-center whitespace-nowrap cursor-pointer backdrop-blur-sm flex justify-center`}
                                         to="/signin"
                                     >
                                         <span className="flex">{t("signin")}</span>
                                     </Link>
                                     <Link
                                         to="/signup"
                                         className="w-1/2 border-2 border-transparent inline bg-orange-500 hover:bg-orange-200 py-1 px-4 rounded-full text-white hover:text-black transition-all text-center whitespace-nowrap cursor-pointer flex justify-center"
                                     >
                                         <span className="flex">{t("signup")}</span>
                                     </Link>
                                 </div>
                                 <hr className="mt-2 mb-4" />
                             </>
                         )}
                         <h2 className="text-sm font-bold text-left text-gray-700">{t("whatSearch")}</h2>
                         <Research
                             kindOfResearch={kindOfResearch}
                             setKindOfResearch={setKindOfResearch}
                             onglet={onglet}
                             setOnglet={setOnglet}
                         />
                         <hr className="mt-5 mb-4" />
                         <h2 className="text-sm font-bold text-left text-gray-700">{t("yourPhotograph")}</h2>
                         <Link to="/iam-photographer">
                             <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-700 p-2 text-sm w-full text-left rounded-lg">
                                 <FontAwesomeIcon icon="check" className="mr-2 w-4" />
                                 {t("yourAdvantages")}
                             </button>
                         </Link>
                         <Link to="/tarifs">
                             <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-700 p-2 text-sm w-full text-left rounded-lg">
                                 <FontAwesomeIcon icon="money-bill" className="mr-2 w-4" />
                                 {t("plansTarifs")}
                             </button>
                         </Link>
                         <Link to="/help">
                             <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-700 p-2 text-sm w-full text-left rounded-lg">
                                 <FontAwesomeIcon icon="question-circle" className="mr-2 w-4" />
                                 {t("helpContact")}
                             </button>
                         </Link>

                         <hr className="mt-2 mb-4" />
                         <h2 className="text-sm font-bold text-left text-gray-700">{t("enterprise")}</h2>
                         <Link to="/about">
                             <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-700 p-2 text-sm w-full text-left rounded-lg">
                                 <FontAwesomeIcon icon="info-circle" className="mr-2 w-4" />
                                 {t("about")}
                             </button>
                         </Link>
                         <HashLink to="/help#contact">
                             <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-700 p-2 text-sm w-full text-left rounded-lg">
                                 <FontAwesomeIcon icon="envelope" className="mr-2 w-4" />
                                 {t("contactus")}
                             </button>
                         </HashLink>
                         <HashLink to="/help#feedbacks">
                             <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-700 p-2 text-sm w-full text-left rounded-lg">
                                 <FontAwesomeIcon icon="briefcase" className="mr-2 w-4" />
                                 {t("jobs")}
                             </button>
                         </HashLink>

                         <hr className="my-2" />
                         <Link to="/CGU">
                             <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-700 p-2 text-sm w-full text-left rounded-lg">
                                 <FontAwesomeIcon icon="file-alt" className="mr-2 w-4" />
                                 {t("privacy_title_12")}
                             </button>
                         </Link>
                         <Link to="/privacy">
                             <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-700 p-2 text-sm w-full text-left rounded-lg">
                                 <FontAwesomeIcon icon="shield" className="mr-2 w-4" />
                                 {t("privacy")}
                             </button>
                         </Link>
                         <Link to="/help">
                             <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-700 p-2 text-sm w-full text-left rounded-lg">
                                 <FontAwesomeIcon icon="question-circle" className="mr-2 w-4" />
                                 {t("helpContact")}
                             </button>
                         </Link>

                         {/* <hr className="my-2" />
                         <div className="flex flex-row flex-wrap items-center justify-around text-center gap-2 my-2">
                             <button onClick={() => changeLanguage(Language.FR)} className="flex flex-row items-center text-center gap-2">
                                 🇫🇷 {t("french_d")}
                             </button>
                             <button onClick={() => changeLanguage(Language.EN)} className="flex flex-row items-center text-center gap-2">
                                 🇬🇧 {t("english_d")}
                             </button>
                             <button onClick={() => changeLanguage(Language.IT)} className="flex flex-row items-center text-center gap-2">
                                 🇮🇹 {t("italiano_d")}
                             </button>
                             <button onClick={() => changeLanguage(Language.DE)} className="flex flex-row items-center text-center gap-2">
                                 🇩🇪 {t("deutsch_d")}
                             </button>
                         </div> */}
                     </div>
                 )}
                 {kindOfResearch && (
                     <div className="py-1">
                         <div className="flex flex-row justify-between items-center">
                             <button
                                 onClick={() => setKindOfResearch("")}
                                 className="my-2 text-gray-700 p-2 text-sm w-fit text-left rounded-lg flex justify-between items-center"
                             >
                                 <div className="flex flex-row items-center">
                                     <FontAwesomeIcon icon="arrow-left" className="mr-2 w-4" />
                                 </div>
                             </button>
                             <h2 className="text-base font-bold text-right text-gray-700 w-full mr-2">{t(kindOfResearch)}</h2>
                         </div>

                         {list.length > 0
                             ? <ReturnMyData />
                         : (
                             <div className="flex flex-col justify-center items-center w-full rounded-lg bg-gray-100 my-2">
                                 <Typography className="text-base text-gray-700 my-4">{t("noData")}</Typography>
                             </div>
                         )}
                     </div>
                 )}
            </Drawer>
        </React.Fragment>
    );
}
