import { useTranslation } from "react-i18next";

import { Button, Menu, MenuHandler, MenuItem, MenuList } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LogoE from "../../assets/images/logos/SVG/logo_e.svg";
import RegionAPI from "../../services/API/Clients/RegionAPI";
import { RootState } from "../../services/redux/store";
import { HashLink } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Footer({ isAbsolute = true }: { isAbsolute?: boolean }) {
    const { t } = useTranslation();
    const [regions, setRegions] = useState([]);
    const general = useSelector((state: RootState) => state.general);

    useEffect(() => {
        const fetchData = async () => {
            const response = await RegionAPI.mostUsedPortfolio();
            if (response.status === 200 || response.status === 201) {
                if (response.body && response.body.length > 4) {
                    const nouveauTableau = response.body.slice(0, 4);
                    setRegions(nouveauTableau);
                } else setRegions(response.body);
            }
        };
        fetchData();
    }, []);

    function getFrLabelForCode(code: string) {
        const region = general.regions.find(function (region: { code: string; frLabel: string; enLabel: string }) {
            return region.code === code;
        });
        return region ? region.frLabel : "";
    }

    return (
        <footer className={`${isAbsolute && "absolute bottom-0 left-0 right-0"} bg-[#2d2c2b] text-white border-t border-gray-200 pt-2 z-20 flex flex-col`}>
            <div className="w-full flex flex-row flex-wrap md:flex-nowrap justify-center items-start pb-4">
                <div className="w-1/2 md:w-auto grow h-full p-4 pr-24">
                    <Link className="cursor-pointer mt-auto" to="/">
                        <LogoE width={40} />
                    </Link>
                </div>
                <div className="w-1/2 md:w-1/6 grow h-full p-2 md:pt-0 flex flex-col gap-1">
                    <span className="font-extrabold text-xs md:text-sm font-sans mb-4">{t("thephotos")}</span>
                    <Link to="/popular" className="text-[9px] md:text-xs font-sans">
                        {t("populars")}
                    </Link>
                    <Link to="/galeries" className="text-[9px] md:text-xs font-sans">
                        {t("galeries")}
                    </Link>
                </div>
                <div className="w-1/2 md:w-1/6 grow h-full p-2 md:pt-0 flex flex-col gap-1">
                    <span className="font-extrabold text-xs md:text-sm font-sans mb-4">{t("thephotographs")}</span>
                    {regions.map((region: string) => (
                        <Link to={`/photographers/region/${region}`} key={region} className="text-[9px] md:text-xs font-sans">
                            {getFrLabelForCode(region)}
                        </Link>
                    ))}
                </div>
                <div className="w-1/2 md:w-1/6 grow h-full p-2 md:pt-0 flex flex-col gap-1">
                    <span className="font-extrabold text-xs md:text-sm font-sans mb-4">{t("youarephotographs")}</span>
                    <Link to="/iam-photographer" className="text-[9px] md:text-xs font-sans">
                        {t("yourAdvantages")}
                    </Link>
                    <Link to="/tarifs" className="text-[9px] md:text-xs font-sans">
                        {t("plansTarifs")}
                    </Link>
                    <Link to="/help" className="text-[9px] md:text-xs font-sans">
                        {t("helpContact")}
                    </Link>
                </div>
                <div className="w-1/2 md:w-1/6 grow h-full p-2 md:pt-0 flex flex-col gap-1">
                    <span className="font-extrabold text-xs md:text-sm font-sans mb-4">{t("thecompany")}</span>
                    <Link to="/about" className="text-[9px] md:text-xs font-sans">
                        {t("about")}
                    </Link>
                    <Link to="/help" className="text-[9px] md:text-xs font-sans">
                        {t("contactus")}
                    </Link>
                    <HashLink to="/help#feedbacks" className="text-[9px] md:text-xs font-sans">
                        {t("jobs")}
                    </HashLink>
                </div>
                <div className="w-1/2 md:w-1/6 grow h-full p-2 md:pt-0 flex flex-col gap-1">
                    <span className="font-extrabold text-xs md:text-sm font-sans mb-4">{t("networks")}</span>
                    <a href="https://www.facebook.com/ezoom.ch" className="text-[9px] md:text-xs font-sans">
                        Facebook
                    </a>
                    <a href="https://www.instagram.com/ezoom.ch" className="text-[9px] md:text-xs font-sans">
                        Instagram
                    </a>
                </div>
            </div>
            <div className="w-full flex flex-row justify-center items-center bg-black">
                <div className="w-full md:w-2/3 flex flex-row justify-center items-center mx-auto">
                    <div className="flex flex-wrap justify-center items-center font-comfortaa text-xs pt-4 pb-2 gap-4">
                        <span className="md:hidden cursor-pointer mt-auto mr-0 mb-2.5 ml-2.5 hover:decoration-1 hover:underline font-bold">© ezoom</span>
                        <div className="md:hidden">
                            <Menu>
                                <MenuHandler>
                                    <Button className="bg-transparent text-xs normal-case text-white align-middle select-none font-sans font-normal text-center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none py-0 px-0 rounded-lg shadow-none shadow-transparent hover:shadow-none hover:shadow-transparent focus:opacity-100 focus:shadow-none active:opacity-100 active:shadow-none -mt-2.5">
                                        Légal
                                        <FontAwesomeIcon icon="chevron-down" className="ml-1 text-xs" />
                                    </Button>
                                </MenuHandler>
                                <MenuList>
                                    <MenuItem>
                                        <Link className="cursor-pointer mt-auto mr-0 ml-2.5 hover:decoration-1 hover:underline" to="/CGU">
                                            {t("CG")}
                                        </Link>
                                    </MenuItem>
                                    <MenuItem>
                                        <Link className="cursor-pointer mt-auto mr-0 ml-2.5 hover:decoration-1 hover:underline" to="/privacy">
                                            {t("privacy")}
                                        </Link>
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </div>
                        <Link className="hidden md:flex cursor-pointer mt-auto mr-0 mb-2.5 ml-2.5 hover:decoration-1 hover:underline" to="/CGU">
                            {t("privacy_title_12")}
                        </Link>
                        <Link className="hidden md:flex cursor-pointer mt-auto mr-0 mb-2.5 ml-2.5 hover:decoration-1 hover:underline" to="/privacy">
                            {t("privacy")}
                        </Link>
                        <Link className="flex cursor-pointer mt-auto mr-0 mb-2.5 ml-2.5 hover:decoration-1 hover:underline" to="/help">
                            {t("helpContact")}
                        </Link>
                        {/* <div className="-mt-2.5" style={{ width: "55px" }}>
                            <Lang />
                        </div> */}
                    </div>
                </div>
            </div>
        </footer>
    );
}
