import { faCcAmex, faCcMastercard, faCcVisa } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-tailwind/react";
import { confirmAlert } from "react-confirm-alert";
import { useCart } from "../../../../providers/CartProvider";
import { Storage } from "../../../../services/storage";
import GradientButton from "../../../Form/Button/GradientButton";
import { PictureType } from "../../../Interfaces/PictureType";

const buyMyPicture = async (onClose: Function, dataActualItem: PictureType, actualId: string) => {
    Storage.setToBuy({ minPath: dataActualItem.minPath, id: actualId, price: dataActualItem?.contract?.basePrice ?? 0 });
    onClose();
    window.location.href = "/checkout?from=uniq";
};

export const handleBuyIt = (handleAddToCart: (onClose: Function) => void, actualId: string, dataActualItem: PictureType, t: any) => {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className="py-4 bg-white rounded-lg shadow-lg border-2 border-gray-300 w-screen lg:w-[60vw] relative">
                    <FontAwesomeIcon icon="x" className="absolute top-2 right-2 text-gray-500 cursor-pointer" onClick={onClose} />
                    <Typography className="text-center w-full text-2xl">{t("get_these_rights")}</Typography>
                    <div className="w-full flex mt-4 justify-center">
                        <div className="w-full flex flex-row flex-wrap px-2">
                            <div className="w-1/3 flex items-center justify-center">
                                <div className="relative w-2/3 h-2/3 py-4 rounded-lg flex flex-col gap-4 items-center">
                                    <FontAwesomeIcon icon="image" className="text-gray-600 h-12 mb-2" />
                                    <div className="flex flex-col">
                                        <Typography className="text-center text-xs text-gray-500">{t("imagejpeg")} {dataActualItem?.width} x {dataActualItem?.height} px</Typography>
                                    </div>
                                </div>
                            </div>
                            <div className="w-1/3 flex items-center justify-center">
                                <div className="relative w-2/3 h-2/3 py-4 rounded-lg flex flex-col gap-4 items-center">
                                    {dataActualItem?.contract?.available === "UNLIMITED" && (
                                        <>
                                            <FontAwesomeIcon icon="infinity" className="text-gray-600 h-12 mb-2" />
                                            <Typography className="text-center text-xs text-gray-500">{t("unlimited_content")}</Typography>
                                        </>
                                    )}
                                    {dataActualItem?.contract?.available === "SINGLE" ? (
                                        <>
                                            <FontAwesomeIcon icon="medal" className="text-gray-600 h-12 mb-2" />
                                            <Typography className="text-center text-xs text-gray-500">{t("unique")}</Typography>
                                        </>
                                    ) : (
                                        dataActualItem?.contract?.available === "LIMITED" && (
                                            <>
                                                <FontAwesomeIcon icon="boxes-stacked" className="text-gray-600 h-12 mb-2" />
                                                <Typography className="text-center text-xs text-gray-500">{t("limited_content")}</Typography>
                                            </>
                                        )
                                    )}
                                </div>
                            </div>
                            <div className="w-1/3 flex items-center justify-center">
                                <div className="relative w-2/3 h-2/3 py-4 rounded-lg flex flex-col gap-4 items-center">
                                    {dataActualItem?.contract?.exclusive ? (
                                        <>
                                            <FontAwesomeIcon icon="file-shield" className="text-gray-600 h-12 mb-2" />
                                            <Typography className="text-center text-xs text-gray-500">{t("exclusive_contract")}</Typography>
                                        </>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon icon="file" className="text-gray-600 h-12 mb-2" />
                                            <Typography className="text-center text-xs text-gray-500">{t("inherit")}</Typography>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="w-1/2 flex items-center justify-center">
                                <div className="relative w-2/3 h-2/3 py-4 rounded-lg flex flex-col gap-4 items-center">
                                    <FontAwesomeIcon icon="ban" className="text-gray-600 h-12 mb-2" />
                                    <div className="flex flex-col">
                                        <Typography className="text-center text-xs text-gray-500">{t("illegal_uses")}</Typography>
                                    </div>
                                </div>
                            </div>
                            <div className="w-1/2 flex items-center justify-center">
                                <div className="relative w-2/3 h-2/3 py-4 rounded-lg flex flex-col gap-4 items-center">
                                    {!dataActualItem?.contract?.commercialUse ? (
                                        <>
                                            <FontAwesomeIcon icon="shop" className="text-gray-600 h-12 mb-2" />
                                            <Typography className="text-center text-xs text-gray-500">{t("commercial_uses_s_a")}</Typography>
                                        </>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon icon="shop-slash" className="text-gray-600 h-12 mb-2" />
                                            <Typography className="text-center text-xs text-gray-500">{t("commercial_uses_s")}</Typography>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex flex-col px-2 items-center justify-center">
                            <h1 className="mt-4 w-full text-center text-2xl">{dataActualItem?.contract?.basePrice} {t("chf")}</h1>
                            <h1 className="w-full text-center text-base text-orange-500 -mt-2">{dataActualItem?.width} x {dataActualItem?.height}</h1>
                            <button className="w-fit mx-auto mt-4" onClick={() => buyMyPicture(onClose, dataActualItem, actualId)}>
                                <GradientButton text={t("buynow")} />
                            </button>
                            <button
                                className="rounded-full border bg-white px-4 py-2 text-sm font-medium  hover:shadow-xl transition-all font-sans my-2"
                                onClick={() => handleAddToCart(onClose)}
                            >
                                {t("addtocart")}
                            </button>
                            <div className="flex flex-row gap-4 mx-auto text-2xl text-gray-400">
                                <FontAwesomeIcon icon={faCcVisa} />
                                <FontAwesomeIcon icon={faCcMastercard} />
                                <FontAwesomeIcon icon={faCcAmex} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        },
    });
};
