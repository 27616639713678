import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Chip } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import DefaultPictureProfil from "../../assets/images/elements/default-profile.png"

export default function CardUserWithData({ data }: Readonly<{ data: { id: string, name: string, picture: string, publicLink: string, plan?: string, verified?: boolean } }>) {
    const { t } = useTranslation();

    const returnColor = () => {
        if (data.plan === "PRO") return "bg-black text-white";
        else if (data.plan === "PHOTOGRAPHER") return "bg-white text-black border border-black";
        else if (data.plan === "PREMIUM") return "bg-white text-black border border-black";
        else return " bg-white text-black border border-black";
    }

    const returnValue = () => {
        if (data.plan === "PRO") return t("pro");
        else if (data.plan === "PHOTOGRAPHER") return t("photographer");
        else if (data.plan === "PREMIUM") return t("premium");
        else return t("user");
    }

    const returnIcon = () => {
        if (data.plan === "PRO") return <FontAwesomeIcon icon="crown" className="h-3.5 mt-0.5" />;
        else if (data.plan === "PHOTOGRAPHER") return <FontAwesomeIcon icon="camera" className="h-3.5 mt-0.5" />;
        else if (data.plan === "PREMIUM") return <FontAwesomeIcon icon="star" className="h-3.5 mt-0.5" />;
        else return <FontAwesomeIcon icon="user" className="h-3.5 mt-0.5" />;
    }

    return (
        <a href={`/${data.publicLink}/photos`} className="flex flex-row items-center gap-2 mt-4 px-2 w-auto rounded-xl py-2">
            <Avatar src={data.picture ?? DefaultPictureProfil} />
            <div className="flex flex-col">
                <p className="text-lg font-bold flex flex-row gap-2 items-center">
                    <span>{data.name}</span>
                    {data.verified && (<FontAwesomeIcon icon="check-circle" className="text-gray-500" />)}
                </p>
                <Chip
                    className={`w-fit h-fit ${returnColor()}`}
                    variant="ghost"
                    value={returnValue()}
                    icon={returnIcon()}
                />
            </div>
        </a>
    );
}
