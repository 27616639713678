import { Chip, IconButton, Popover, PopoverContent, PopoverHandler, Spinner } from "@material-tailwind/react";
import CardUserWithData from "../../CardUserWithData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PictureModalEdit from "./PictureModalEdit";
import { PieChart } from "react-minimal-pie-chart";
import { useTranslation } from "react-i18next";
import { PictureType } from "../../../Interfaces/PictureType";
import { useSelector } from "react-redux";
import GaleryForPhoto from "../../Gallery/GaleryForPhoto";
import moment from "moment";
import { DateDiffBetweenTwo } from "../../../DateDiffBetweenTwo";
import SharingBox from "../../SharingBox";
import { usePictureEdit } from "../../../../providers/PictureEditProvider";
import { useCart } from "../../../../providers/CartProvider";

export default function PictureModalWithoutFullScreen({
    dataActualItem,
    repartition,
    actualId,
    loading,
    openReportModal,
    handleChangeFav,
    onSaveEdit,
    handleBuyIt,
    handleDelete,
}: Readonly<{
    dataActualItem: PictureType;
    repartition: { users: number; photographers: number };
    actualId: string;
    loading: boolean;
    openReportModal: Function;
    handleChangeFav: Function;
    handleBuyIt: Function;
    handleDelete: Function;
    onSaveEdit: () => void;
}>) {
    const general = useSelector((state: any) => state.general);
    const { t } = useTranslation();

    const { setEditedPicture } = usePictureEdit();

    const onEditPicture = () => {
        setEditedPicture({ id: actualId, onSaveEdit });
    }

    const { addToCart } = useCart();

    const handleAddToCart = (onClose: Function) => {
        const item = { id: actualId, minPath: dataActualItem.minPath, price: dataActualItem?.contract?.basePrice ?? 0 };
        addToCart(item);
        onClose();
    }

    

    return (
        <div className="flex flex-col bg-gray-50">
            <div className="flex flex-row flex-wrap md:flex-nowrap justify-between px-8 items-center md:items-start md:mt-4 w-screen max-w-7xl mx-auto">
                <div className="w-1/2 md:w-1/3 flex flex-row justify-start my-1 md:my-0 grow">
                    {!loading ? (
                        dataActualItem?.portfolio?.name && <CardUserWithData data={dataActualItem.portfolio} />
                    ) : (
                        <div className="flex flex-row items-center gap-2 mt-4 px-2 w-auto rounded-xl py-2 animate-pulse bg-gray-200">
                            <div className="rounded-full h-[48px] w-[48px] bg-gray-500/50"></div>
                            <div className="flex flex-col">
                                <p className="text-lg font-bold flex flex-row gap-2 items-center bg-gray-400/50 h-6 mb-1 rounded w-24">
                                    <span></span>
                                </p>
                                <Chip className={`w-fit h-fit bg-gray-400/50 h-5 w-12`} variant="ghost" value="" />
                            </div>
                        </div>
                    )}
                </div>
                <div className="w-full md:w-2/3 flex flex-col-reverse md:flex-row-reverse justify-between">
                    <div className="w-full md:w-1/2 flex flex-row justify-end mt-2.5 md:my-0 gap-2 grow">
                        {dataActualItem.portfolio?.id !== general?.me?.portfolio?.id && (
                            <>
                                {loading ? (
                                    <IconButton color="white" className={`ring-1 bg-gray-400/50 animate-pulse ring-gray-500/40 rounded-full  transition-all`}>
                                        <></>
                                    </IconButton>
                                ) : (
                                    <>
                                        <IconButton
                                            onClick={() => handleChangeFav()}
                                            color={dataActualItem.isFavorited ? "red" : "white"}
                                            className={`ring-1 ${
                                                dataActualItem.isFavorited ? "ring-red-500/50 hover:bg-red-400" : "ring-gray-400/50 hover:bg-gray-50"
                                            } rounded-full  transition-all hidden md:flex`}
                                        >
                                            <FontAwesomeIcon icon="heart" />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => handleChangeFav()}
                                            color={dataActualItem.isFavorited ? "red" : "white"}
                                            className={`ring-1 ${
                                                dataActualItem.isFavorited ? "ring-red-500/50 hover:bg-red-400" : "ring-gray-400/50 hover:bg-gray-50"
                                            } rounded-full  transition-all md:hidden`}
                                            size="sm"
                                        >
                                            <FontAwesomeIcon icon="heart" />
                                        </IconButton>
                                    </>
                                )}
                            </>
                        )}
                        {dataActualItem?.portfolio?.id !== general?.me?.portfolio?.id && dataActualItem?.canBeBought && (
                            <>
                                {loading ? (
                                    <IconButton color="white" className={`ring-1 bg-gray-400/50 animate-pulse ring-gray-500/40 rounded-full  transition-all`}>
                                        <></>
                                    </IconButton>
                                ) : (
                                    <>
                                        <IconButton
                                            color="orange"
                                            className="hidden md:flex hover:rounded-full transition-all duration-300 rounded-full hover:bg-orange-200"
                                            onClick={() => handleBuyIt(handleAddToCart, actualId, dataActualItem, t)}
                                        >
                                            <FontAwesomeIcon icon="cart-shopping" />
                                        </IconButton>
                                        <IconButton
                                            color="orange"
                                            className="md:hidden hover:rounded-full transition-all duration-300 rounded-full hover:bg-orange-200"
                                            onClick={() => handleBuyIt(handleAddToCart, actualId, dataActualItem, t)}
                                            size="sm"
                                        >
                                            <FontAwesomeIcon icon="cart-shopping" />
                                        </IconButton>
                                    </>
                                )}
                            </>
                        )}
                        {dataActualItem?.portfolio?.id === general?.me?.portfolio?.id && (
                            <>
                                {loading ? (
                                    <IconButton color="white" className={`ring-1 bg-gray-400/50 animate-pulse ring-gray-500/40 rounded-full  transition-all`}>
                                        <></>
                                    </IconButton>
                                ) : (
                                    <>
                                        <IconButton
                                            color="orange"
                                            className="hidden md:flex hover:rounded-full transition-all duration-300 rounded-full hover:bg-orange-200"
                                            onClick={() => handleDelete(actualId, t)}
                                        >
                                            <FontAwesomeIcon icon="trash" />
                                        </IconButton>
                                        <IconButton
                                            color="orange"
                                            className="md:hidden hover:rounded-full transition-all duration-300 rounded-full hover:bg-orange-200"
                                            onClick={() => handleDelete(actualId, t)}
                                            size="sm"
                                        >
                                            <FontAwesomeIcon icon="trash" />
                                        </IconButton>
                                    </>
                                )}
                            </>
                        )}

                        {dataActualItem?.portfolio?.id === general?.me?.portfolio?.id && (
                            <>
                                {loading ? (
                                    <IconButton color="white" className={`ring-1 bg-gray-400/50 animate-pulse ring-gray-500/40 rounded-full  transition-all`}>
                                        <></>
                                    </IconButton>
                                ) : (<>
                                    <IconButton
                                        color="white"
                                        className="hidden md:flex ring-1 ring-gray-400/50 hover:rounded-full transition-all duration-300 rounded-full hover:bg-gray-50"
                                        onClick={() => onEditPicture()}
                                    >
                                        <FontAwesomeIcon icon="pen" />
                                    </IconButton>
                                    <IconButton
                                        color="white"
                                        className="md:hidden ring-1 ring-gray-400/50 hover:rounded-full transition-all duration-300 rounded-full hover:bg-gray-50"
                                        onClick={() => onEditPicture()}
                                        size="sm"
                                    >
                                        <FontAwesomeIcon icon="pen" />
                                    </IconButton>
                                </>
                                )}
                            </>
                        )}
                        <SharingBox loading={loading} />
                        {dataActualItem?.portfolio?.id !== general?.me?.portfolio?.id && (
                            <>
                                {loading ? (
                                    <IconButton color="white" className={`ring-1 bg-gray-400/50 animate-pulse ring-gray-500/40 rounded-full  transition-all`}>
                                        <></>
                                    </IconButton>
                                ) : (
                                    <Popover placement="bottom-end">
                                        <PopoverHandler>
                                            <>
                                                <IconButton
                                                    color="white"
                                                    className="hidden md:flex ring-1 ring-gray-400/50 hover:rounded-full transition-all duration-300 rounded-full hover:bg-gray-50"
                                                >
                                                    <FontAwesomeIcon icon="ellipsis-vertical" />
                                                </IconButton>
                                                <IconButton
                                                    color="white"
                                                    size="sm"
                                                    className="md:hidden ring-1 ring-gray-400/50 hover:rounded-full transition-all duration-300 rounded-full hover:bg-gray-50"
                                                >
                                                    <FontAwesomeIcon icon="ellipsis-vertical" />
                                                </IconButton>
                                            </>
                                        </PopoverHandler>
                                        <PopoverContent className="p-0 z-40">
                                            <button
                                                className="py-2 px-4 rounded-lg hover:bg-orange-500 hover:text-white w-full"
                                                onClick={() => openReportModal(actualId, t)}
                                            >
                                                {t("report")}
                                            </button>
                                        </PopoverContent>
                                    </Popover>
                                )}
                            </>
                        )}
                    </div>
                    <div className="w-full md:w-1/2 flex flex-row justify-center mt-2.5 gap-2">
                        {loading ? (
                            <>
                                <Chip className={`w-fit h-fit bg-gray-400/50 h-5 w-12`} variant="ghost" value="" />
                                <Chip className={`w-fit h-fit bg-gray-400/50 h-5 w-12`} variant="ghost" value="" />
                            </>
                        ) :
                            <>
                                <Chip
                                    className="w-fit h-fit"
                                    color="gray"
                                    variant="ghost"
                                    value={`${dataActualItem.viewCount} ${t("views")}`}
                                    icon={<FontAwesomeIcon icon="eye" className="h-4 mt-0.5" />}
                                />
                                <Popover placement="bottom">
                                    <PopoverHandler>
                                        <Chip
                                            className="w-fit h-fit cursor-pointer"
                                            color="gray"
                                            variant="ghost"
                                            value={`${dataActualItem.favoriteCount} ${t("favs")}`}
                                            icon={<FontAwesomeIcon icon="heart" className="h-4 mt-0.5" />}
                                        />
                                    </PopoverHandler>
                                    <PopoverContent className="z-40">
                                        <div className="flex flex-row gap-2 items-center">
                                            {repartition.users !== 0 || repartition.photographers !== 0 ? (
                                                <PieChart
                                                    data={[
                                                        { title: t("users"), value: repartition.users ?? 0, color: "rgb(239, 147, 53)" },
                                                        { title: t("photographs"), value: repartition.photographers ?? 0, color: "rgb(98, 81, 153)" },
                                                    ]}
                                                    style={{ width: "100px", height: "100px" }}
                                                />
                                            ) : (
                                                <PieChart data={[{ title: t("users"), value: 100, color: "#d4d4d4" }]} style={{ width: "100px", height: "100px" }} />
                                            )}
                                            <div className="flex flex-col gap-2">
                                                <div className="flex flex-row gap-2 items-center">
                                                    <div className="w-4 h-4 bg-orange-400 rounded-full"></div>
                                                    <span>{t("users")}</span>
                                                    <span>{repartition.users ?? 0}%</span>
                                                </div>
                                                <div className="flex flex-row gap-2 items-center">
                                                    <div className="w-4 h-4 bg-purple-400 rounded-full"></div>
                                                    <span>{t("photographs")}</span>
                                                    <span>{repartition.photographers ?? 0}%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </PopoverContent>
                                </Popover>
                            </>
                        }
                    </div>
                </div>
            </div>

            <div className="flex flex-col md:flex-row justify-center w-screen max-w-7xl mx-auto">
                <div className="flex flex-col justify-left items-center w-full md:w-1/2 px-6 mt-4 mb-4">
                    {loading ? (
                        <div className="bg-gray-400/50 w-full h-32 rounded-lg animate-pulse"></div>
                    ) : (
                        <div className="flex flex-col w-full p-4 rounded-lg bg-gray-200">
                            <p className={`text-base mb-4 ${dataActualItem && dataActualItem.description && dataActualItem.description === "" && "italic"}`}>
                                {dataActualItem && dataActualItem.description && dataActualItem.description !== ""
                                    ? dataActualItem && dataActualItem.description
                                    : t("notgivend")}
                            </p>
                            <div className="flex flex-row gap-2">
                                <FontAwesomeIcon icon="map-marker-alt" className="text-gray-600" />
                                <p className="text-sm">{dataActualItem && dataActualItem.place ? dataActualItem.place.label : t("notgiven")}</p>
                                <FontAwesomeIcon icon="calendar-alt" className="ml-2 text-gray-600" />
                                <p className="text-sm">
                                    {dataActualItem && dataActualItem.uploadedAt && moment(dataActualItem && dataActualItem.uploadedAt).format("DD.MM.YYYY")}
                                </p>
                            </div>
                            <p className="text-sm text-gray-500 italic mt-4">
                                {dataActualItem &&
                                    dataActualItem.uploadedAt &&
                                    t("posted", { time: DateDiffBetweenTwo(moment(dataActualItem.uploadedAt), moment()) })}
                            </p>
                        </div>
                    )}
                </div>
                <div className="flex flex-col justify-left items-center w-full md:w-1/2 px-6 md:mt-4 mb-4">
                    {loading ? (
                        <div className="bg-gray-400/50 w-full h-16 rounded-lg animate-pulse"></div>
                    ) : (
                        <>
                            <div className="flex flex-row flex-wrap w-full items-center p-4 rounded-lg bg-gray-200">
                                <FontAwesomeIcon icon="tags" className="text-gray-600" />
                                <a href={dataActualItem?.category?.enumValue && `/search/category/${dataActualItem.category.enumValue}`}>
                                    <div className="ml-2 text-xs inline-flex items-center font-extrabold leading-sm uppercase px-3 py-1 bg-orange-200/30 text-orange-700 rounded-full">
                                        {dataActualItem?.category?.frLabel}
                                    </div>
                                </a>
                                {dataActualItem?.typeStyle?.frLabel && dataActualItem?.typeStyle?.frLabel !== "" && (
                                    <a
                                        href={dataActualItem?.typeStyle?.enumValue && `/search/typestyle/${dataActualItem.typeStyle.enumValue}`}
                                    >
                                        <div className="ml-2 text-xs inline-flex items-center font-extrabold leading-sm uppercase px-3 py-1 bg-orange-200/30 text-orange-700 rounded-full">
                                            {dataActualItem?.typeStyle?.frLabel}
                                        </div>
                                    </a>
                                )}
                                {dataActualItem?.keywords?.length > 0 &&
                                    dataActualItem?.keywords?.map((tag) => (
                                        <a href={`/search/keyword/${tag.customLabel}`} key={tag.index}>
                                            <div className="ml-2 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-orange-200/30 text-orange-700 rounded-full">
                                                {tag.customLabel ?? tag.keyword?.frLabel}
                                            </div>
                                        </a>
                                    ))}
                            </div>
                            {dataActualItem?.galleries?.length > 0 && (
                                <div className="flex flex-row flex-wrap w-full items-center p-4 rounded-lg bg-gray-200 mt-4">
                                    <p className={`text-base mb-4 font-bold`}>{t("ingallery")}</p>
                                    <GaleryForPhoto
                                        img="https://img.freepik.com/photos-gratuite/vue-verticale-du-celebre-rocher-plage-santa-giulia_268835-3741.jpg"
                                        name="Chiaroscuro Captures"
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
