import { GoogleOAuthProvider } from "@react-oauth/google";
import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Page404 from "./Bundle/404/Page404";

import CGUNewsletter from "./Other/CGUNewsletter";
import Teaser from "./Teaser/Teaser";

import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import RedirectTo from "../components/RedirectTo";
import ScrollToTop from "../components/Scroll/ScrollToTop";
import { PictureModalProvider } from "../providers/PictureModalProvider";
import { updateGeneral } from "../services/redux/actions/generalActions";
import { RootState } from "../services/redux/store";
import { Storage } from "../services/storage";
import { dataLoggedIn, dataUnlogged } from "./fetchData";
import IamPhotographer from "./Home/IamPhotographer/IamPhotographer";
import { CartProvider } from "../providers/CartProvider";
import { PictureEditProvider } from "../providers/PictureEditProvider";
import Privacy from "./Other/Privacy";

const token = Storage.getToken();

export default function AppBride() {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [dataFetched, setDataFetched] = React.useState<boolean>(true);
    const general = useSelector((state: RootState) => state.general);
    const dispatch = useDispatch();

    const fetchData = async (isLoggedin: boolean) => {
        try {
            if (!general.dataFetched) {
                const state = await (isLoggedin ? dataLoggedIn() : dataUnlogged());
                await dispatch(updateGeneral(state));
                setDataFetched(false);
                setLoading(false);
            } else {
                setDataFetched(false);
                setLoading(false);
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchData(!!token);
    }, []);

    if (loading) {
        return <Loader loading={loading} />;
    }

    if (dataFetched) {
        return <Loader loading={dataFetched} />;
    }

    if (!general.dataFetched) {
        return <Loader loading={!general.dataFetched} />;
    }

    const clientId = process.env.REACT_APP_GOOGLE_CODE_OAUTH ?? "";
    return (
        <BrowserRouter>
            <CartProvider>
                <PictureEditProvider>
                    <PictureModalProvider>
                        <GoogleOAuthProvider clientId={clientId}>
                            <ScrollToTop />
                            <Routes>
                                <Route path="/" element={<Navigate to="/teaser" />} />
                                <Route path="/iam-photographer" element={<Page404 />} />
                                <Route path="/teaser" element={<Teaser />} />

                                <Route path="/signin" element={<Page404 />} />
                                <Route path="/signup" element={<Page404 />} />
                                <Route path="/confirm-signup" element={<Page404 />} />
                                <Route path="/verify-email" element={<Page404 />} />
                                <Route path="/forgotpassword" element={<Page404 />} />
                                <Route path="/forgot-password" element={<Page404 />} />
                                <Route path="/phone_confirmation" element={<Page404 />} />
                                <Route path="/2fa" element={<Page404 />} />

                                <Route path="/favorites" element={<Page404 />} />
                                <Route path="/favorites/:type" element={<Page404 />} />

                                <Route path="/gallery/:hashid/edit" element={<Page404 />} />
                                <Route path="/gallery/:hashid" element={<Page404 />} />

                                <Route path="/portfolio">
                                    <Route path="edit" element={<Page404 />}>
                                        <Route path="photos" element={<Page404 />} />
                                        <Route path="about" element={<Page404 />} />
                                        <Route path="performances" element={<Page404 />} />
                                        <Route path="performance/:perf" element={<Page404 />} />
                                        <Route path="addPerf" element={<Page404 />} />
                                    </Route>
                                    <Route path=":id" element={<Page404 />}>
                                        <Route path="photos" element={<Page404 />} />
                                        <Route path="about" element={<Page404 />} />
                                        <Route path="performances" element={<Page404 />} />
                                        <Route path="performance/:perf" element={<Page404 />} />
                                        <Route path="opinion" element={<Page404 />} />
                                        <Route path="contact" element={<Page404 />} />
                                        <Route path="offers" element={<Page404 />} />
                                        <Route path="appointment" element={<Page404 />} />
                                        <Route path="inquiry" element={<Page404 />} />
                                    </Route>
                                </Route>

                                <Route path="/my-portfolio" element={<Page404 />} />

                                <Route path="/recent" element={<Page404 />} />
                                <Route path="/popular" element={<Page404 />} />
                                <Route path="/galeries" element={<Page404 />} />
                                <Route path="/search">
                                    <Route path="" element={<Page404 />} />
                                    <Route path=":type/:value" element={<Page404 />} />
                                </Route>

                                <Route path="/photographers">
                                    <Route path="" element={<Page404 />} />
                                    <Route path=":type/:value" element={<Page404 />} />
                                </Route>

                                <Route path="/popular-photographers" element={<Page404 />} />
                                <Route path="/recent-photographers" element={<Page404 />} />

                                <Route path="/account" element={<Page404 />}>
                                    <Route path="data" element={<Page404 />} />
                                    <Route path="portfolio" element={<Page404 />} />
                                    <Route path="preferences" element={<Page404 />} />
                                    <Route path="security" element={<Page404 />} />
                                    <Route path="subscription" element={<Page404 />} />
                                    <Route path="contracts" element={<Page404 />} />
                                    <Route path="*" element={<Page404 />} />
                                </Route>

                                <Route path="/sales" element={<Page404 />} />
                                <Route path="/sales/:type" element={<Page404 />} />
                                <Route path="/purchases" element={<Page404 />} />
                                <Route path="/statistics" element={<Page404 />} />
                                <Route path="/statistics/:type" element={<Page404 />} />
                                <Route path="/subscription/:sub_type" element={<Page404 />} />
                                <Route path="/checkout" element={<Page404 />} />

                                <Route path="/upload" element={<Page404 />} />

                                <Route path="/contact/:type/:id" element={<Page404 />} />

                                <Route path="/contracts" element={<Page404 />} />
                                <Route path="/contracts/:id" element={<Page404 />} />
                                <Route path="/download/:id" element={<Page404 />} />
                                <Route path="/checkout/:arg" element={<Page404 />} />
                                <Route path="/contact" element={<Page404 />} />
                                <Route path="/about" element={<Page404 />} />
                                <Route path="/privacy" element={<Privacy />} />
                                <Route path="/CGU" element={<Page404 />} />
                                <Route path="/CGU-newsletter" element={<CGUNewsletter />} />
                                <Route path="/CGV" element={<Page404 />} />
                                <Route path="/avis" element={<Page404 />} />
                                <Route path="/tarifs" element={<Page404 />} />
                                <Route path="/help" element={<Page404 />} />

                                <Route path="/unsubscribe-newsletter/:id" element={<Page404 />} />

                                <Route path="/:portfolio" element={<Page404 />}>
                                    <Route path="photos" element={<Page404 />} />
                                    <Route path="about" element={<Page404 />} />
                                    <Route path="performances" element={<Page404 />} />
                                    <Route path="performance/:perf" element={<Page404 />} />
                                    <Route path="opinion" element={<Page404 />} />
                                    <Route path="contact" element={<Page404 />} />
                                    <Route path="offers" element={<Page404 />} />
                                    <Route path="appointment" element={<Page404 />} />
                                    <Route path="inquiry" element={<Page404 />} />
                                </Route>

                                {/* <Route path="/signin" element={<RedirectTo href="signin" />} />
                                <Route path="/signup" element={<RedirectTo href="signup" />} />
                                <Route path="/confirm-signup" element={<RedirectTo href="confirm-signup" />} />
                                <Route path="/verify-email" element={<RedirectTo href="verify-email" />} />
                                <Route path="/forgotpassword" element={<RedirectTo href="forgotpassword" />} />
                                <Route path="/forgot-password" element={<RedirectTo href="forgot-password" />} />
                                <Route path="/phone_confirmation" element={<RedirectTo href="phone_confirmation" />} />
                                <Route path="/2fa" element={<RedirectTo href="2fa" />} />

                                <Route path="/favorites" element={<RedirectTo href="favorites" />} />
                                <Route path="/favorites/:type" element={<RedirectTo href="favorites" />} />

                                <Route path="/gallery/:hashid/edit" element={<RedirectTo href="gallery" />} />
                                <Route path="/gallery/:hashid" element={<RedirectTo href="gallery" />} />

                                <Route path="/portfolio">
                                    <Route path="edit" element={<RedirectTo href="photographers" />}>
                                        <Route path="photos" element={<RedirectTo href="photographers" />} />
                                        <Route path="about" element={<RedirectTo href="photographers" />} />
                                        <Route path="performances" element={<RedirectTo href="photographers" />} />
                                        <Route path="performance/:perf" element={<RedirectTo href="photographers" />} />
                                        <Route path="addPerf" element={<RedirectTo href="photographers" />} />
                                    </Route>
                                    <Route path=":id" element={<RedirectTo href="photographers" />}>
                                        <Route path="photos" element={<RedirectTo href="photographers" />} />
                                        <Route path="about" element={<RedirectTo href="photographers" />} />
                                        <Route path="performances" element={<RedirectTo href="photographers" />} />
                                        <Route path="performance/:perf" element={<RedirectTo href="photographers" />} />
                                        <Route path="opinion" element={<RedirectTo href="photographers" />} />
                                        <Route path="contact" element={<RedirectTo href="photographers" />} />
                                        <Route path="offers" element={<RedirectTo href="photographers" />} />
                                        <Route path="appointment" element={<RedirectTo href="photographers" />} />
                                        <Route path="inquiry" element={<RedirectTo href="photographers" />} />
                                    </Route>
                                </Route>

                                <Route path="/my-portfolio" element={<RedirectTo href="my-portfolio" />} />

                                <Route path="/recent" element={<RedirectTo href="recent" />} />
                                <Route path="/popular" element={<RedirectTo href="popular" />} />
                                <Route path="/galeries" element={<RedirectTo href="galeries" />} />
                                <Route path="/search">
                                    <Route path="" element={<RedirectTo href="search" />} />
                                    <Route path=":type/:value" element={<RedirectTo href="search" />} />
                                </Route>

                                <Route path="/photographers">
                                    <Route path="" element={<RedirectTo href="photographers" />} />
                                    <Route path=":type/:value" element={<RedirectTo href="photographers" />} />
                                </Route>

                                <Route path="/popular-photographers" element={<RedirectTo href="popular-photographers" />} />
                                <Route path="/recent-photographers" element={<RedirectTo href="recent-photographers" />} />

                                <Route path="/account" element={<RedirectTo href="account" />}>
                                    <Route path="data" element={<RedirectTo href="account/data" />} />
                                    <Route path="portfolio" element={<RedirectTo href="account/portfolio" />} />
                                    <Route path="preferences" element={<RedirectTo href="account/preferences" />} />
                                    <Route path="security" element={<RedirectTo href="account/security" />} />
                                    <Route path="subscription" element={<RedirectTo href="account/subscription" />} />
                                    <Route path="contracts" element={<RedirectTo href="account/contracts" />} />
                                    <Route path="*" element={<RedirectTo href="account/data" />} />
                                </Route>

                                <Route path="/sales" element={<RedirectTo href="sales" />} />
                                <Route path="/sales/:type" element={<RedirectTo href="sales" />} />
                                <Route path="/purchases" element={<RedirectTo href="purchases" />} />
                                <Route path="/statistics" element={<RedirectTo href="statistics" />} />
                                <Route path="/statistics/:type" element={<RedirectTo href="statistics" />} />
                                <Route path="/subscription/:sub_type" element={<RedirectTo href="subscription/premium" />} />
                                <Route path="/checkout" element={<RedirectTo href="checkout" />} />

                                <Route path="/upload" element={<RedirectTo href="upload" />} />

                                <Route path="/contact/:type/:id" element={<RedirectTo href="contact" />} />

                                <Route path="/contracts" element={<RedirectTo href="search" />} />
                                <Route path="/contracts/:id" element={<RedirectTo href="search" />} />
                                <Route path="/download/:id" element={<RedirectTo href="search" />} />
                                <Route path="/checkout/:arg" element={<RedirectTo href="checkout" />} />
                                <Route path="/contact" element={<RedirectTo href="contact" />} />
                                <Route path="/about" element={<RedirectTo href="about" />} />
                                <Route path="/privacy" element={<RedirectTo href="privacy" />} />
                                <Route path="/CGU" element={<RedirectTo href="CGU" />} />
                                <Route path="/CGU-newsletter" element={<CGUNewsletter />} />
                                <Route path="/CGV" element={<RedirectTo href="CGV" />} />
                                <Route path="/avis" element={<RedirectTo href="avis" />} />
                                <Route path="/tarifs" element={<RedirectTo href="tarifs" />} />
                                <Route path="/help" element={<RedirectTo href="help" />} />

                                <Route path="/unsubscribe-newsletter/:id" element={<RedirectTo href="" />} />

                                <Route path="/:portfolio" element={<RedirectTo href="photographers" />}>
                                    <Route path="photos" element={<RedirectTo href="photographers" />} />
                                    <Route path="about" element={<RedirectTo href="photographers" />} />
                                    <Route path="performances" element={<RedirectTo href="photographers" />} />
                                    <Route path="performance/:perf" element={<RedirectTo href="photographers" />} />
                                    <Route path="opinion" element={<RedirectTo href="photographers" />} />
                                    <Route path="contact" element={<RedirectTo href="photographers" />} />
                                    <Route path="offers" element={<RedirectTo href="photographers" />} />
                                    <Route path="appointment" element={<RedirectTo href="photographers" />} />
                                    <Route path="inquiry" element={<RedirectTo href="photographers" />} />
                                </Route> */}
                                <Route path="/*" element={<Page404 />} />
                            </Routes>
                            <ToastContainer
                                position="bottom-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                            />
                        </GoogleOAuthProvider>
                    </PictureModalProvider>
                </PictureEditProvider>
            </CartProvider>
        </BrowserRouter>
    );
}
