import { confirmAlert } from "react-confirm-alert";
import { Storage } from "../../../../services/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-tailwind/react";

export const removeNsfw = (setPrintNsfw: Function, t: any) => {
    confirmAlert({
        customUI: ({ onClose }) => {
            const wantToClose = () => {
                if (Storage.getCookie() === "true") setPrintNsfw(true);
                else setPrintNsfw(false);
                onClose();
            };

            return (
                <div className="py-4 bg-white rounded-lg shadow-lg border-2 border-gray-300 w-screen lg:w-[60vw] relative">
                    <FontAwesomeIcon icon="x" className="absolute top-2 right-2 text-gray-500 cursor-pointer" onClick={onClose} />
                    <Typography className="text-center w-full text-2xl">{t("nsfw")}</Typography>
                    <div className="w-full flex flex-col px-8 mt-4 justify-center">
                        <div className="mt-6">
                            <p className="text-lg text-center text-gray-500">{t("wanttoseensfw")}</p>
                        </div>
                        <div className="flex justify-center gap-4 mt-6">
                            <button
                                type="button"
                                className="rounded-full border bg-white px-4 py-2 text-sm font-medium  hover:shadow-xl transition-all font-sans"
                                onClick={() => {
                                    Storage.setCookie();
                                    wantToClose();
                                }}
                            >
                                {t("show")}
                            </button>
                            <button
                                type="button"
                                className="rounded-full text-white bg-indian-500 px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                                onClick={onClose}
                            >
                                {t("cancel")}
                            </button>
                        </div>
                    </div>
                </div>
            );
        },
    });
};
