import APIClient from "../APIClients";

export default class ContractsAPI {
    public static async contracts(id: string) {
        return APIClient.ezoom.get(`/contracts?user=${id}`);
    }

    public static async newContract(data: {}) {
        return APIClient.ezoom.post(`/contracts`, data);
    }

    public static async getContract (id: string) {
        return APIClient.ezoom.get(`/contracts/${id}`);
    }

    public static async getContractPdf (id: string) {
        return APIClient.ezoom.getPdf(`/contracts/${id}`);
    }

    public static async putContract(data: {}, id: string) {
        return APIClient.ezoom.put(`/contracts/${id}`, data);
    }

    public static async deleteContract(id: string) {
        return APIClient.ezoom.delete(`/contracts/${id}`);
    }

    public static async staticContract(id: string) {
        return APIClient.ezoom.getPdf(`/static-contracts/${id}`);
    }
}
