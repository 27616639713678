import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../../components/Footer/Footer";
import HeaderForAnimation from "../../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../../components/NavBar/HeaderMobile";

export default function Page404() {
    document.title = "ezoom | 404";
    const { t } = useTranslation();
    const [searchBar, setSearchBar] = React.useState(false);

    return (
        <div className="min-h-screen">
            <HeaderForAnimation />
            <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
            <div className="mt-12 mx-auto  relative w-10/12 max-w-7xl font-extralight text-montserrat" style={{ minWidth: '300px', height: 'calc(100vh - 215px)' }}>
                <div className="absolute top-1/3 md:top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 z-20">
                    <h1 className="font-sans text-9xl font-semibold text-center">404</h1>
                    <p className="text-center">{t("pagenotfound")}</p>
                </div>
                <div className="grid grid-cols-2 grid-rows-2 h-full opacity-20 gap-5">
                    {["https://static-cse.canva.com/blob/996500/Sanstitre.jpg", "https://img-19.commentcamarche.net/wzKKufHO7dLH-WPFdXJHEmOmi7E=/1500x/smart/2d8c2b30aee345008ee860087f8bcdc9/ccmcms-commentcamarche/36120212.jpg", "https://st3.depositphotos.com/9292400/15758/i/450/depositphotos_157582674-stock-photo-sunset-in-the-sky-of.jpg", "https://st.depositphotos.com/49609376/54960/i/450/depositphotos_549606214-stock-photo-sky-cloud-bright-rainbow-colors.jpg"].map((image, index) => (
                        <div key={index} style={{ backgroundImage: `url('${image}')` }} className="rounded-xl bg-cover bg-center"></div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    );
}
