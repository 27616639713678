// index.ts
import { configureStore } from '@reduxjs/toolkit'
import formReducer from '../reducers/formReducer';
import contractReducer from '../reducers/contractReducer';
import userReducer from '../reducers/UserReducer';
import littleUserReducer from '../reducers/LittleUserReducer';
import GeneralReducer from '../reducers/generalReducer';
import filtersReducer from '../reducers/filtersReducer';
const reducerConfig = {
    form: formReducer,
    contract: contractReducer,
    user: userReducer,
    littleUser: littleUserReducer,
    general: GeneralReducer,
    filters: filtersReducer
};
const store = configureStore({
  reducer: reducerConfig
});

export type RootState = ReturnType<typeof store.getState>

export default store;
