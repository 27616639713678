import { ReactConfirmAlertProps, confirmAlert } from "react-confirm-alert";
import PicturesAPI from "../../../../services/API/Clients/PicturesAPI";
import { toast } from "react-toastify";

const deleteAlert = (deleteRoutine: () => void, t: any): ReactConfirmAlertProps => ({
    title: t("delete"),
    message: t("delete_d"),
    buttons: [
        {
            label: t("delete"),
            className: "ezoom-alert-cancel",
            onClick: deleteRoutine,
        },
        {
            label: t("keep"),
        },
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
    keyCodeForClose: [8, 32],
    overlayClassName: "ezoom-alert-overlay",
});

export const handleDelete = (actualId: string, t: any) => {
    confirmAlert(
        deleteAlert(async () => {
            const response = await PicturesAPI.deletePicture(actualId);
            if (response.status === 200 || response.status === 201 || response.status === 204) {
                toast.success(t("deleteSuccess"));
                window.location.reload();
            } else {
                toast.error(t("deleteError"));
            }
        }, t)
    );
};
