import { useTranslation } from "react-i18next";
import Select from "react-select";
import { PlaceCustomStyle } from "../../../../../components/CustomStyles/PlaceCustomStyle";

export default function SelectForUpload({ name, options, handleChangeSelect, optionChoose }: Readonly<{ name: string, options: any, handleChangeSelect?: (event: any) => void, optionChoose?: string}>) {
    const { t } = useTranslation();

    const selectedOption = options.find((option: any) => { return option.value === optionChoose });

    return (
        <Select
            placeholder={t(name)}
            name={t(name)}
            className="w-full relative top-0 bg-white border-orange-500"
            options={options}
            styles={PlaceCustomStyle}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            onChange={handleChangeSelect}
            value={optionChoose ? selectedOption : null}
        />
    );
}
