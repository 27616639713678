import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import React from "react";
import { Link, useLocation } from "react-router-dom";
import useWindowWidth from "../WindowSize";
import DoubleBar from "./DoubleBar";
import SubMenu from "./SubMenu";
import Notifications from "./composants/Notifications";
import Profil from "./composants/Profil";

import { Tooltip } from "@material-tailwind/react";
import FullLogoEzoom from "../../assets/images/logos/SVG/logo.svg";
import FullLogoEzoomBeta from "../../assets/images/logos/ezoombeta.png";
import LogoE from "../../assets/images/logos/SVG/logo_e.svg";
import MenuHeader from "./composants/MenuHeader";
import MenuProfil from "./composants/MenuProfil";

function ColorPhoto(pathname: string) {
    if (pathname === "/recent" || pathname === "/popular" || pathname === "/galeries" || pathname === "/search") return "text-white bg-orange-400 border-0";
    return pathname === "/photographers" || pathname === "/popular-photographers"
        ? "bg-white text-gray-500 border-gray-400 border-r-transparent hover:text-orange-500 hover:border-orange-500"
        : "bg-white text-gray-500 border-gray-400 hover:text-orange-500 hover:border-orange-500 hover:z-10";
}

function ColorGrapher(pathname: string) {
    if (pathname === "/photographers" || pathname === "/popular-photographers") return "text-white bg-purple-400 border-0";
    return pathname === "/recent" || pathname === "/popular" || pathname === "/galeries" || pathname === "/search"
        ? "bg-white text-gray-500 border-gray-400 border-l-transparent hover:text-purple-500 hover:border-purple-500"
        : "bg-white text-gray-500 border-gray-400 hover:text-purple-500 hover:border-purple-500";
}

interface HeaderForAnimationProps {
    portfolio?: {
        name: string;
        path: string;
    };
}

export default function HeaderForAnimation(props: HeaderForAnimationProps) {
    const { t } = useTranslation();
    const windowWidth = useWindowWidth();
    const location = useLocation();
    const pathname = location.pathname;
    const url = new URL(window.location.href);
    const mainParam = url.searchParams.get("query");
    const [openMenu, setOpenMenu] = React.useState({
        notification: false,
        profil: false,
    });
    const [searchBar, setSearchBar] = React.useState(false);
    // const [selectSearch, setSelectSearch] = React.useState({
    //     option: "photo",
    //     textPhoto: "",
    //     openSuggestions: false,
    // });

    const signin = localStorage.getItem("token") ?? false;

    return (
        <nav className="hidden md:block w-full sticky top-0 left-0 z-40 -mt-2.5">
            <div className="overflow-visible bg-white pt-0.5 md:pt-0 shadow-md shadow-var">
                <div
                    className={`md:my-2.5 mx-2 md:mx-4 ${
                        pathname === "/search" ||
                        pathname === "/favorites" ||
                        pathname === "/favorites/photographers" ||
                        pathname === "/photographers" ||
                        pathname === "/popular-photographers" ||
                        pathname === "/recent" ||
                        pathname === "/galeries" ||
                        pathname === "/popular" ||
                        pathname.startsWith("/search/category") ||
                        pathname.startsWith("/search/typestyle") ||
                        pathname.startsWith("/search/license") ||
                        pathname.startsWith("/search/region") ||
                        pathname.startsWith("/search/date") ||
    
                        pathname.startsWith("/photographers/category") ||
                        pathname.startsWith("/photographers/category") ||
                        pathname.startsWith("/photographers/region")
                            ? "pb-2.5"
                            : "pb-0"
                    }  relative flex flex-col md:flex-row`}
                >
                    <div className="my-1.5 md:my-0 hidden md:flex flex-around flex-row w-full md:w-11/12 gap-1 md:gap-4">
                        <Link to="/" className="flex items-center ml-1" style={{ maxWidth: "250px" }}>
                            {windowWidth > 768 ? (
                                // <FullLogoEzoom style={{ height: "35px" }} alt="full_logo_ezoom" className="h-full" />
                                <img src={FullLogoEzoomBeta} alt="full_logo_ezoom" className="w-[200px]" />
                            ) : (
                                <>
                                    <LogoE style={{ height: "40px" }} alt="little_logo_ezoom" className="h-full" />
                                </>
                            )}
                        </Link>
                        <div className="flex m-auto relative w-full" style={{ lineHeight: 0 }}>
                            <div className="w-full">
                                <DoubleBar portfolio={props.portfolio} />
                            </div>
                            <div id="submenu_buttons" className="rounded-lg">
                                <div className={`flex float-right ${signin ? "w-24" : "w-28"}`}>
                                    <div className="group flex relative">
                                        <Tooltip
                                            content={t("photos")}
                                            placement="bottom-end"
                                            animate={{
                                                mount: { scale: 1, y: 0 },
                                                unmount: { scale: 0, y: -25, x: 10 },
                                            }}
                                            className="group-hover:opacity-100 transition-opacity bg-orange-400 px-2 text-sm text-white rounded-md z-40 opacity-0"
                                        >
                                            <Link
                                                to="/popular"
                                                className={`${ColorPhoto(
                                                    pathname
                                                )} transition-all py-2 px-3 cursor-pointer border rounded-l-full w-12 h-10 flex items-center justify-end`}
                                            >
                                                <FontAwesomeIcon icon="image" className="text-base" />
                                            </Link>
                                        </Tooltip>
                                    </div>
                                    <div className="group flex relative">
                                        <Tooltip
                                            content={t("photographs")}
                                            placement="bottom-start"
                                            animate={{
                                                mount: { scale: 1, y: 0 },
                                                unmount: { scale: 0, y: -25, x: -40 },
                                            }}
                                            className="group-hover:opacity-100 transition-opacity bg-purple-400 px-2 text-sm text-white rounded-md z-40 opacity-0"
                                        >
                                            <Link
                                                to="/popular-photographers"
                                                className={`${ColorGrapher(
                                                    pathname
                                                )} transition-all py-2 px-3 cursor-pointer border rounded-r-full w-12 h-10 flex items-center justify-start`}
                                                style={{ marginLeft: "-1px" }}
                                            >
                                                <FontAwesomeIcon icon="users" className="text-base" />
                                            </Link>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="my-1.5 md:my-0 flex md:hidden flex-around flex-row w-full md:w-11/12 gap-1 md:gap-4">
                        <Link to="/" className="flex items-center ml-1" style={{ maxWidth: "250px" }}>
                            <FullLogoEzoom style={{ height: "30px" }} alt="full_logo_ezoom" />
                        </Link>
                        <div className="flex m-auto relative w-full justify-end pr-1 items-center gap-1" style={{ lineHeight: 0 }}>
                            <button
                                onClick={() => setSearchBar(!searchBar)}
                                className="flex items-center justify-center text-center cursor-pointer text-base font-extralight w-6 py-2 px-2 h-full hover:bg-gray-100 rounded-full bg-white text-gray-500 hover:text-gray-700"
                            >
                                <FontAwesomeIcon icon="magnifying-glass" className="text-base text-gray-500 hover:text-orange-500" />
                            </button>
                            {signin && (
                                <>
                                    <Tooltip
                                        content={t("importPictures")}
                                        placement="bottom"
                                        animate={{
                                            mount: { scale: 1, y: 0 },
                                            unmount: { scale: 0, y: -25, x: 0 },
                                        }}
                                        className="group-hover:opacity-100 transition-opacity bg-orange-400 px-2 text-sm text-white rounded-md z-40 opacity-0"
                                    >
                                        <Link
                                            className="flex items-center justify-center text-center cursor-pointer text-base font-extralight w-6 h-full hover:bg-gray-100 rounded-full bg-white text-gray-500 hover:text-gray-700"
                                            to="/upload"
                                        >
                                            <FontAwesomeIcon icon="add" className="text-base text-gray-500 hover:text-orange-500" />
                                        </Link>
                                    </Tooltip>
                                    <MenuProfil />
                                </>
                            )}
                        </div>
                        <MenuHeader />
                    </div>
                    {signin ? (
                        <div className="hidden md:flex justify-around items-center gap-2 text-left mx-0 w-screen md:mt-3 sm:w-auto">
                            <Tooltip
                                content={t("importPictures")}
                                placement="bottom"
                                animate={{
                                    mount: { scale: 1, y: 0 },
                                    unmount: { scale: 0, y: -25, x: 0 },
                                }}
                                className="group-hover:opacity-100 transition-opacity bg-orange-400 px-2 text-sm text-white rounded-md z-40 opacity-0"
                            >
                                <Link
                                    className="flex items-center justify-center text-center cursor-pointer text-base font-extralight w-8 h-8 hover:bg-gray-100 rounded-full bg-white text-gray-500 hover:text-gray-700 -mt-2.5 ml-2"
                                    to="/upload"
                                >
                                    <FontAwesomeIcon icon="add" />
                                </Link>
                            </Tooltip>
                            <div className=" -mt-2.5">
                                <Notifications
                                    isOpen={openMenu.notification}
                                    handleOpen={() => setOpenMenu({ ...openMenu, notification: !openMenu.notification })}
                                />
                            </div>
                            <Profil isOpen={openMenu.profil} handleOpen={() => setOpenMenu({ ...openMenu, profil: !openMenu.profil })} />
                            <MenuHeader />
                        </div>
                    ) : (
                        <div className="hidden md:flex flex-row items-center justify-around text-center gap-2 my-2">
                            <Link
                                className={`border-2 border-transparent inline w-auto bg-gray-200/30 hover:bg-white py-1 px-4 rounded-full text-black hover:shadow-lg transition-all text-center whitespace-nowrap cursor-pointer backdrop-blur-sm`}
                                to="/signin"
                            >
                                <span className="hidden xl:flex">{t("signin")}</span>
                                <FontAwesomeIcon icon="right-to-bracket" className="xl:hidden hover:text-gray-900" />
                            </Link>
                            <Link to="/signup">
                                <button
                                    className="border-2 bg-orange-500 hover:bg-orange-200 border-transparent inline w-auto text-white hover:text-black py-1 px-4 rounded-full"
                                    style={{
                                        transition: "all .25s ease-in-out",
                                    }}
                                >
                                    <span className="hidden xl:flex">{t("signup")}</span>
                                    <FontAwesomeIcon icon="user-plus" className="xl:hidden text-white hover:text-black" />
                                </button>
                            </Link>
                            <div className="">
                                <MenuHeader />
                            </div>
                        </div>
                    )}
                </div>
                {((
                    pathname === "/favorites" ||
                    pathname === "/favorites/photographers" ||
                    pathname === "/popular-photographers" ||
                    pathname === "/recent" ||
                    pathname === "/recent-photographers" ||
                    pathname === "/galeries" ||
                    pathname.startsWith("/search/category") ||
                    pathname.startsWith("/search/typestyle") ||
                    pathname.startsWith("/search/license") ||
                    pathname.startsWith("/search/region") ||
                    pathname.startsWith("/search/date") ||

                    pathname.startsWith("/photographers/category") ||
                    pathname.startsWith("/photographers/category") ||
                    pathname.startsWith("/photographers/region") ||
                    pathname === "/popular") && !mainParam) ? <SubMenu pathname={pathname} /> : <></>}
                <div
                    className={`${
                        searchBar ? "flex h-12 " : "flex h-0"
                    } md:hidden w-full mx-auto flex-wrap justify-between text-base px-2 transition-all bg-white`}
                >
                    <DoubleBar />
                </div>
            </div>
        </nav>
    );
}
